<template>
  <div>
    <!-- <div :class="fill ? s.circle : s.circle_nofill" /> -->
    <div :class="fill1 ? s.urchin : s.circle_nofill" />
    <div :class="fill2 ? s.urchin2 : s.circle_nofill" />
    <div :class="fill3 ? s.urchin3 : s.circle_nofill" />
    <div :class="fill4 ? s.urchin4 : s.circle_nofill" />
    <div :class="s.urchin_cover" />
    <div :class="s.kv_wrap">
      <div :class="[s.bg, s.sp]"><img :src="`/img/pages/scoring/kv/bgs/sp/${type}.jpg`" alt=""></div>
      <div :class="[s.bg, s.pc]"><img :src="`/img/pages/scoring/kv/bgs/pc/${type}.jpg`" alt=""></div>
      <div :class="s.logo"><router-link to="/"><img :src="`/img/logos/choikuse_bg_transparent.svg`" alt=""></router-link></div>
      <div :class="[s.gif, s[type]]"><img :src="`/img/pages/scoring/kv/gifs/${type}.gif`" alt=""></div>
      <div :class="s.name_wrap">
        <div :class="s.name_container">
          <div :class="s.name_frame"><img :src="`/img/pages/scoring/name_frame.svg`" alt=""></div>
          <div :class="s.name">{{ names[type] }}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div :class="s.wrap" v-if="detail">
    <div :class="s.bg" />
    <div :class="s.parts_container">
      
    </div>
    <div :class="s.character_wrap">
      <div :class="s.character_bg"/>

      <div :class="s.features">
        <div 
          v-for="(feature, i) in detail.keywords"
          :key="i"
          :class="s.feature"
          v-html="feature"/>
        <div :class="s.feature"><img src="/img/pages/scoring/future.svg" alt=""></div>
      </div>
      
      <div :class="s.choikuse">
        <img src="/img/logos/choikuse_bg_transparent.svg" alt="">
      </div>
      
      <div :class="s.character">
        <img :src="detail.img" alt="">
      </div>
      <div :class="s.name_wrap">
        <div :class="s.name">
          <img src="/img/pages/scoring/name_frame.svg" alt="">
          <div>{{ detail.name }}</div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  props: {
    // detail: {
    //   type: Object,
    // },
    type: {
      type: String,
    },
  },
  data() {
    return {
      fill1: false,
      fill2: false,
      fill3: false,
      fill4: false,
      names: {
        enf: 'がんばルッコラ',
        isf: 'さんざインゲン',
        est: 'コスパプリカ',
        inf: 'せんさイチジク',
        esf: 'チェケラディッシュ',
        int: 'けんキウイ',
        ist: 'ミステリコリス',
      },
    };
  },
  async created() {
    // 進化のアニメーションが入るのは診断から来た際のみ
    if (this.$route.query.effect && this.$route.query.effect === 'enable') {
      this.fill4 = true;
      await this.delay(250);
      this.fill3 = true;
      await this.delay(250);
      this.fill2 = true;
      await this.delay(250);
      this.fill1 = true;
      await this.delay(10);
      const query = {};
      Object.keys(this.$route.query).forEach((key) => {
        if (key !== 'effect') query[key] = this.$route.query[key];
      });
      this.$router.replace({
        path: this.$route.path,
        query,
      });
    }
  },
  methods: {
    // アニメーションの待ち時間
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style lang="scss" module="s">
.kv_wrap {
  position: relative;
  > * {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .logo {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 38%;
    transform: translate(-50%, 0);
  }
  .gif {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.enf {
      width: 80%;
      left: 55%;
    }
    &.isf {
      width: 50%;
    }
    &.est {
      width: 40%;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(10deg);
    }
    &.inf {
      width: 45%;
      top: 55%;
      left: 52%;
    }
    &.esf {
      width: 40%;
    }
    &.int {
      width: 70%;
      left: 45%;
    }
    &.ist {
      width: 30%;
    }
  }
  .name_wrap {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 50%;
  }
  .name_container {
    position: relative;
    width: 100%;
    height: fit-content;
  }
  .name_frame {
    position: relative;
    width: 100%;
  }
  .name {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 32px;
    font-weight: 700;
    transform: translate(-45%, -50%);
  }
}

// 円を描画
.circle {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: var(--primary-pink); /* 円の色 */
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: expand 1.5s;
}

// 中心に向かって縮小
@keyframes expand {
  0% {
    width: 200vmax; /* 画面全体を覆う */
    height: 200vmax; /* 画面全体を覆う */
    opacity: 1;
    z-index: 100;
  }
  100% {
    width: 0;
    height: 0;
    opacity: 1;
    z-index: 100;
  }
}

// トゲトゲが縮小
.urchin {
  position: fixed;
  top: 150%;
  left: 50%;
  width: 300vmax;
  height: 300vmax;
  z-index: -2;
  background-image: url('/img/effects/toge_a.svg');
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  animation: deflate .6s;
}

.urchin2 {
  position: fixed;
  top: 150%;
  left: 50%;
  width: 300vmax;
  height: 300vmax;
  z-index: -2;
  background-image: url('/img/effects/toge_b.svg');
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  animation: deflate .6s;
}

.urchin3 {
  position: fixed;
  top: 150%;
  left: 50%;
  width: 300vmax;
  height: 300vmax;
  z-index: -2;
  background-image: url('/img/effects/toge_c.svg');
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  animation: deflate .6s;
}

.urchin4 {
  position: fixed;
  top: 150%;
  left: 50%;
  width: 300vmax;
  height: 300vmax;
  z-index: -2;
  background-image: url('/img/effects/toge_a.svg');
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  animation: deflate .6s;
}

.urchin_cover { // アニメーションのトゲトゲが背景にいたのでカバー
  position: fixed;
  width: 100vmax;
  height: 100vmax;
  z-index: -1;
  background-color: white;
}

// 中心に向かって縮小
@keyframes deflate {
  0% {
    top: calc(100vmax + 50%);
    width: 400vmax; // 横幅、縦幅ともに画面全体を覆う
    height: 400vmax;
    opacity: 1;
    z-index: 100;
  }
  100% {
    top: 50%;
    width: 0;
    height: 0;
    opacity: 1;
    z-index: 100;
  }
}

@include mdView {
  .kv_wrap {
    .name {
      font-size: 20px;
    }
  }
}

@include smView {
  .kv_wrap {
    .logo {
      top: 5%;
      width: 64%;
    }
    .gif {
      &.enf {
        width: 92%;
      }
      &.isf {
        width: 80%;
      }
      &.est {
        width: 60%;
      }
      &.inf {
        width: 70%;
        top: 55%;
        left: 55%;
      }
      &.esf {
        width: 60%;
      }
      &.int {
        width: 90%;
        left: 50%;
      }
      &.ist {
        width: 50%;
        top: 53%;
      }
    }
    .name_wrap {
      width: 80%;
      bottom: 5%;
    }
    .name {
      font-size: 22px;
    }
  }
}

@media (max-width: 600px) {
  .kv_wrap {
    .name {
      font-size: 18px;
    }
  }
}

@include xsView {
  .kv_wrap {
    .name {
      font-size: 13px;
    }
  }
}

.pc {
  @include smView {
    display: none;
  }
}
.sp {
  display: none;
  @include smView {
    display: block;
  }
}


// $h: max(319px, 53vw);
// .wrap {
//   // height: calc($h + var(--header-h));
//   height: $h;
//   overflow: hidden;
//   position: relative;
//   .bg {
//     position: absolute;
//     z-index: 1;
//     top: 50%;
//     left: 50%;
//     height: 200%;
//     width: 200%;
//     transform: translate(-50%, -50%) rotate(5deg);
//     background-color: var(--primary-pink);
//     background-image: url('/img/pages/scoring/scoring_kv_bg.png');
//     background-size: 200px auto;
//     background-position: center;
//     background-repeat: repeat;
//   }
// }

// $top-mgn: 35px;
// $top-radius: 65px;
// $c-area-w: 87.5vw;
// $c-area-h: calc($h - $top-mgn);
// .character_wrap {
//   position: relative;
//   margin: $top-mgn auto 0;
//   z-index: 2;
//   width: $c-area-w;
//   height: calc(100% - $top-mgn);
//   border-radius: $top-radius $top-radius 0 0;
//   border: 1px solid var(--primary-black);
//   border-bottom: none;
//   background-color: var(--primary-pink);
//   background-image: url('/img/pages/scoring/kv_togetoge.png');
//   background-size: 173% 200%;
//   background-position: center 46%;
//   background-repeat: no-repeat;
// }

// .choikuse {
//   position: absolute;
//   top: 0;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 73%;
//   max-width: 260px;
//   object-fit: contain;
//   > img {
//     width: 100%;
//   }
// }

// .features {
//   width: 100%;
//   height: 100%;
//   position: relative;

//   .feature {
//     position: absolute;
//     font-weight: 700;
//     font-size: 16px;
//     white-space: pre-wrap;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     line-height: 1.4;

//     // green
//     &:nth-child(1) {
//       border: 1px solid var(--primary-black);
//       background-color: var(--primary-green);
//       width: 47%;
//       height: calc($c-area-h * .23);
//       left: 15%;
//       top: calc($c-area-h * .17);
//       border-radius: 100px;
//       transform: translate(-50%, -50%) rotate(5deg);
//       transform-origin: center;
//     }

//     // white
//     &:nth-child(2) {
//       width: 43.2%;
//       height: calc($c-area-h * .374);
//       left: 11%;
//       top: 45%;
//       transform: translate(-50%, -50%) rotate(-10deg);
//       background-image: url('/img/pages/scoring/heart_white.svg');
//       background-size: contain;
//       background-repeat: no-repeat;
//       background-position: center;
//     }

//     // yellow
//     &:nth-child(3) {
//       width: 30%;
//       height: calc($c-area-h * .374);
//       left: 8%;
//       top: 70%;
//       transform: translate(-50%, -50%) rotate(-10deg);
//       background-image: url('/img/pages/scoring/toge_yellow.svg');
//       background-size: contain;
//       background-repeat: no-repeat;
//       background-position: center;
//     }

//     // pink
//     &:nth-child(4) {
//       width: 60%;
//       height: calc($c-area-h * .374);
//       right: 10%;
//       top: 29%;
//       transform: translate(50%, -50%);
//       background-image: url('/img/pages/scoring/toge_pink.svg');
//       background-size: contain;
//       background-repeat: no-repeat;
//       background-position: center;
//     }

//     // green
//     &:nth-child(5) {
//       border: 1px solid var(--primary-black);
//       background-color: var(--primary-green);
//       width: 47%;
//       max-width: 280px;
//       height: calc($c-area-h * .1);
//       right: 15%;
//       top: 73%;
//       border-radius: 100px;
//       transform: translate(50%, -50%) rotate(-10deg);
//       transform-origin: center;
//     }

//     // future
//     &:nth-child(6) {
//       width: 28%;
//       height: calc($c-area-h * .1);
//       right: 15%;
//       top: 58%;
//       border-radius: 100px;
//       transform: translate(50%, -50%) rotate(-20deg);
//       transform-origin: center;
//       object-fit: contain;
//       > img {
//         height: 100%;
//         width: 100%;
//       }
//     }
//   }
// }

// .character {
//   position: absolute;
//   width: 60%;
//   height: 60%;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   > img {
//     object-fit: contain;
//     width: 100%;
//     height: 100%;
//   }
// }

// .name_wrap {
//   position: absolute;
//   width: 87%;
//   max-width: 280px;
//   bottom: 10px;
//   left: 50%;
//   transform: translate(-50%, 0);
//   .name {
//     position: relative;
//     > img {
//       object-fit: contain;
//       width: 100%;
//       height: 100%;
//     }
//     > div {
//       position: absolute;
//       width: 100%;
//       text-align: center;
//       top: 50%;
//       left: 53%;
//       transform: translate(-50%, -50%);
//       font-weight: bold;
//       font-size: 14px;
//     }
//   }

// }

// @include smView {
//   .features {
//     .feature {
//       font-size: 12px;
//     }
//   }
// }

// @include xsView {
//   .features {
//     .feature {
//       font-size: 10px;
//     }
//   }
// }
</style>
