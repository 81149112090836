<template>
  <div>
    <div :class="s.wrap">
      <div :class="s.main_logo">
        <img src="/img/logos/choikuse_bg_white.svg" alt="">
      </div>
      <!-- <div :class="s.base"/> -->
      <!-- main title -->
      <!-- <div :class="s.content">
        <div
          v-for="(letter, i) in title"
          :key="i"
          :class="s.letter">
          <div :class="s.img_wrap">
            <img
              :src="letter"
              alt=""
              :class="s.img">
            <div :class="s.img_dot"/>
            <div :class="s.img_outline"/>
          </div>
        </div>
      </div> -->
    </div>
    <div :class="s.wrap">
      <!-- sub title -->
      <div :class="s.growyourmoney">
        <img src="/img/logos/growyourmoney.svg" alt="">
      </div>
      <!-- <div :class="s.subcontent">
        <div
          v-for="(letter, i) in subtitle"
          :key="i"
          :class="s.subletter">
          <img :src="`/img/pages/growyourmoney/${letter}.png`" alt="">
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: [
        '/img/pages/choikuse/chi.png',
        '/img/pages/choikuse/lyo.png',
        '/img/pages/choikuse/i.png',
        '/img/pages/choikuse/ku.png',
        '/img/pages/choikuse/se.png',
        '/img/pages/choikuse/mi.png',
        '/img/pages/choikuse/rai.png',
        '/img/pages/choikuse/yo.png',
        '/img/pages/choikuse/hou.png',
      ],
      subtitle: 'GROWYOURMONEY',
    };
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  height: fit-content;
  position: relative;  
}
.main_logo {
  width: 100%;
  max-width: 1014px;
}
.growyourmoney {
  margin: -3% auto 0;
  width: 62%;
  object-fit: contain;
}

// 分解した場合のスタイル（レスポンシブ未対応）
.base {
  width: 100%;
  padding-top: 16%;
}
.content {
  position: absolute;
  top: 0; left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  display: flex;
  align-items: flex-end;
}

$width: 30px, 12px, 24px, 12px, 31px, 24px, 24px, 25px, 37px;
$bottom: 4.4px, 4.4px, 4.4px, 4.4px, 4.4px, 16.7px, 12.3px, 16.7px, 12.3px;
$right: 1.6px, 1.6px, 1.6px, 1.6px, -2.3px, 1.6px, 1.6px, 1.6px, 0;
$mask-imgs: 
  '/img/pages/choikuse/chi_bg.png',
  '/img/pages/choikuse/lyo_bg.png',
  '/img/pages/choikuse/i_bg.png',
  '/img/pages/choikuse/ku_bg.png',
  '/img/pages/choikuse/se_bg.png',
  '/img/pages/choikuse/mi_bg.png',
  '/img/pages/choikuse/rai_bg.png',
  '/img/pages/choikuse/yo_bg.png',
  '/img/pages/choikuse/hou_bg.png';
$outline-imgs: 
  '/img/pages/choikuse/chi_bg_bdr.png',
  '/img/pages/choikuse/lyo_bg_bdr.png',
  '/img/pages/choikuse/i_bg_bdr.png',
  '/img/pages/choikuse/ku_bg_bdr.png',
  '/img/pages/choikuse/se_bg_bdr.png',
  '/img/pages/choikuse/mi_bg_bdr.png',
  '/img/pages/choikuse/rai_bg_bdr.png',
  '/img/pages/choikuse/yo_bg_bdr.png',
  '/img/pages/choikuse/hou_bg_bdr.png';
.letter {
  @for $i from 1 through 9 {
    &:nth-child(#{$i}) {
      width: calc(nth($width, $i) * 1.3);
      margin-bottom: calc(nth($bottom, $i) * 1.3);
      margin-right: calc(nth($right, $i) * 1.3);
      .img {
        position: relative;
        z-index: 3;
        &_wrap {
          position: relative;
        }
        &_outline {
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: 100% 100%;
          background-image: url(nth($outline-imgs, $i));
          top: 4.5px;
          left: 3.9px;
          z-index: 2;
        }
        &_dot {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 4.5px;
          left: 3.9px;
          mask-image: url(nth($mask-imgs, $i));
          mask-size: 100% 100%;
          background-color: var(--primary-white);
          background-size: 50px;
          background-image: url('/img/effects/dot_backimage.png');
          z-index: 1;
        }
      }
    }
  }
}
.letter {
  @for $i from 1 through 9 {
    &:nth-child(#{$i}) {
      @include xsView {
        width: calc(nth($width, $i) * 1);
        margin-bottom: calc(nth($bottom, $i) * 1);
        margin-right: calc(nth($right, $i) * 1);
      }
      @include smView {
      }
    }
  }
}


.subcontent {
  width: 68%;
  display: flex;
  align-items: flex-end;
  .subletter {
    width: fit-content;
    animation: tiltFade .5s;
    img {
      height: 5vw;
      width: auto;
    }

    $delay-unit: .03s;
    $pb: 0, 0.5%, 1%, 1.5%, 2.5%, 3%, 3.5%, 4%, 5%, 5.5%, 6%, 6.5%, 7%;
    $mr: -2%, -2%, -2%, 2%, -2%, -2%, -2%, 2%, -2%, -2%, -2%, -2%, -2%;
    @for $i from 1 to 14 {
      &:nth-child(#{$i}) { 
        margin-right: nth($mr, $i);
        padding-bottom: nth($pb, $i);
        animation-delay: $delay-unit * ($i - 1);
      }
    }
  }
}

@keyframes slide {
  0% { top: 0; left: 0; }
  100% { top: 16%; left: 16%; }
}

@keyframes tiltFade {
  0% {
    transform: rotate(-15deg);
    opacity: 0;
  }
  50% {
    transform: rotate(15deg);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}
</style>
