<template>
  <footer :class="[s.footer, s[bgColor]]" v-if="isShowFooter" id="footer-container">
    <div v-if="showShibon" :class="s.jouro"><img src="/img/pages/footer/jouro.gif" alt=""></div>
    <div
      v-if="isShowUfo"
      :style="{
        left: `${ufoPos.left}px`,
        bottom: `${ufoPos.bottom}px`,
      }"
      :class="[s.ufo, isShowUfo ? s.show : s.hide]"><img :src="ufoSrc" alt=""></div>
    <div
      v-if="showShibon"
      :class="[s.shibons, s.sp]">
      <div v-for="n of 3" :key="n">
        <img
          @click="n === 2 ? seebonClicked() : ''"
          :src="isShibonSurprised ? shibonsWithUfo[n] : shibons[n]" alt="">
      </div>
    </div>
    <div
      v-if="showShibon"
      :class="[s.shibons, s.pc]">
      <div v-for="(n, i) in [2,3,1,2,3,1,2]" :key="n">
        <img
          @click="i === 3 ? seebonClicked() : ''"
          :src="isShibonSurprised ? shibonsWithUfo[n] : shibons[n]" alt="">
      </div>
    </div>
    <div :class="s.main">
      <div v-if="showShare">
        <div :class="s.share_title">「ちょいくプロジェクト」を<br :class="s.xs">友達にシェアしよう！</div>
        <div :class="s.share">
          <a
            :href="`http://x.com/share?url=https%3A%2F%2Fchoiku.jp`"
            :class="s.share_btn">シェアする</a>
        </div>
      </div>
      <div :class="s.sns">
        <a href="https://www.youtube.com/@seeboncho" target="_blank"><img src="/img/sns/icon_youtube_white.png" alt=""></a>
        <a href="https://www.tiktok.com/@seeboncho" target="_blank"><img src="/img/sns/icon_tictok_white.png" alt=""></a>
        <a href="https://x.com/seeboncho" target="_blank"><img src="/img/sns/icon_x_white.png" alt=""></a>
        <!-- <a href="/"><img :src="['black'].includes(bgColor) ? '/img/sns/icon_insta_white.png' : '/img/sns/icon_insta.png'" alt=""></a> -->
      </div>
      <div :class="s.c">©2024 The Investment Trusts Association, Japan All rights reserved.｜ 一般社団法人投資信託協会</div>
    </div>
  </footer>
</template>

<script>
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'GlobalFooter',
  mixins: [cf],
  data() {
    return {
      isShowUfo: false,
      isShibonSurprised: false,
      shibons: {
        1: '/img/characters/gifs/seebon_yellow.gif',
        2: '/img/characters/gifs/seebon_clover.gif',
        3: '/img/characters/gifs/seebon_pink.gif',
      },
      shibonsWithUfo: {
        2: '/img/pages/footer/seebon_reaction_2.gif',
        1: '/img/pages/footer/seebon_reaction_1.gif',
        3: '/img/pages/footer/seebon_reaction_3.gif',
      },
      ufoPos: {
        left: 0,
        bottom: 0,
      },
      ufoSrc: '/img/pages/footer/ufo.svg',
      ufoItems: [
        '/img/pages/footer/ufo.svg',
        '/img/pages/footer/star.svg',
        '/img/pages/footer/heart.svg',
      ],
    };
  },
  components: {
  },
  computed: {
    showShibon() {
      const excludePageName = ['Scoring'];
      return !excludePageName.includes(this.$route.name);
    },
    bgColor() {
      const exceptionBgPages = {
        Scoring: 'black',
      };
      return exceptionBgPages[this.$route.name] || 'pink';
    },
    showShare() {
      const excludePageName = ['Scoring'];
      return !excludePageName.includes(this.$route.name);
    },
    isShowFooter() {
      const hideFooterPage = ['Diagnosis'];
      return !hideFooterPage.includes(this.$route.name);
    },
  },
  created() {
  },
  methods: {
    seebonClicked() {
      if (this.isShowUfo) return;
      // ランダムでUFO設定
      const now = new Date();
      const second = this.formatTimestamp(now, 'ss');
      this.ufoSrc = this.ufoItems[second % 3];

      this.isShowUfo = true;

      let count = 0;
      const intervalId = setInterval(() => {
        count += 1;
        if (count === 10) this.isShibonSurprised = true; // 少し遅れてシーボンが驚く

        if (count >= 20 && count < 40) this.setUfoPositionAtCenter(count);
        else this.setUfoPosition(count);

        if (count >= 60) { // 6秒間 (0.1秒 x 60)
          this.isShowUfo = false;
          this.isShibonSurprised = false;
          setTimeout(this.setUfoPosition(0), 100);
          clearInterval(intervalId);
        }
      }, 100); 

    },
    setUfoPosition(i) {
      const n = i < 20 ? i : i - 20;
      const containerElem = document.getElementById('footer-container');
      const containerH = containerElem?.clientHeight;
      const containerW = containerElem?.clientWidth;
      const windowSize = containerW < 768 ? 'sp' : 'pc';
      const x = 20 - n;
      const ufoW = windowSize === 'sp' ? containerW * 0.2 : containerW * 0.1;
      const a = windowSize === 'sp' ? 0.4 : 1;
      const y = x * x * a; // y方向の位置は二次関数で決める
      const w = containerW + ufoW;
      const seebonCount = windowSize === 'sp' ? 3 : 6;
      const bottomBase = containerH + (containerW / seebonCount) * (19 / 15) + 100;
      this.ufoPos.bottom = bottomBase + y;
      this.ufoPos.left = w - ufoW / 2 - (w / 40) * (n + 1);
    },
    setUfoPositionAtCenter(i) {
      const n = i < 30 ? 20 - i : 30 - i;
      const containerElem = document.getElementById('footer-container');
      const containerH = containerElem?.clientHeight;
      const containerW = containerElem?.clientWidth;
      const windowSize = containerW < 768 ? 'sp' : 'pc';
      const ufoW = windowSize === 'sp' ? containerW * 0.2 : containerW * 0.1;
      const w = containerW + ufoW;
      const a = windowSize === 'sp' ? 0.4 : 1;
      const x = n + 5;
      const y = x * x * a; // y方向の位置は二次関数で決める
      const seebonCount = windowSize === 'sp' ? 3 : 6;
      const bottomBase = containerH + (containerW / seebonCount) * (19 / 15) + 100;
      this.ufoPos.bottom = bottomBase + y;
      this.ufoPos.left = w - ufoW / 2 - (w / 40) * (20 + 1);
    },
  },
};
</script>

<style lang="scss" module="s">
.footer {
  z-index: 30;
  background-color: var(--primary-pink);
  position: relative;

  &::before {
    z-index: 50;
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    // height: calc(12vw / 300);
    height: 100px;
    bottom: 99%;
    background-image: url('/img/effects/giza_pink.svg');
    background-size: 100%;
    background-position: center bottom;
    background-repeat: repeat-x;
    pointer-events: none;
  }

  &.black {
    color: var(--primary-white);
    &::before {
      pointer-events: none;
      background-image: url('/img/effects/giza_black.svg');
    }
    .main {
      background-color: var(--primary-black);
    }
  }
}

.jouro {
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 17%;
  transform: translateY(-100vw / 6 * .8);
  > img {
    width: 100%;
  }
}

.ufo {
  position: absolute;
  transition: .1s;
  transform: translate(-50%, 50%);
  width: 10%;
  left: 120%;
  > img {
    width: 100%;
  }
}

.shibons {
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 97%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: nowrap;
  overflow: hidden;
  > * {
    flex-shrink: 0;
    width: calc((100% - 40px) / 6);
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 160%;
      transform: translateY(20%);
    }
    &:not(:first-child) {
      margin-left: 5px;
    }
    &:nth-child(2n) {
      transform: translateY(5%);
    }
  }
}

.main {
  position: relative;
  background-color: var(--primary-pink);
  z-index: 10;
  height: 100%;
  padding: 68px 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share_title {
  font-size: 48px;
  font-weight: 700;
  text-align: center;
}
.share {
  margin: 30px auto 0;
  text-align: center;
  &_btn {
    font-size: 44px;
    text-align: center;
    padding: 12px 100px;
    min-width: 760px;
    border-radius: 50px;
    font-weight: 700;
    display: inline-block;
    border: 1px solid var(--primary-black);
    color: var(--primary-black);
    background-color: var(--primary-white);
    transition: all .3s;
    &:hover {
      background-color: var(--primary-black);
      border-color: var(--primary-white);
      color: var(--primary-white);
    }
  }
}
.sns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  > * {
    transition: all .3s;
    > * {
      height: 74px;
    }
    &:hover {
      opacity: .5;
    }
    &:not(:first-child) {
      margin-left: 45px;
    }
  }
}
.c {
  margin-top: 30px;
  text-align: center;
  font-size: 20px;
}

.sp {
  display: none;
  @include  smView {
    display: flex;
  }
}
.xs {
  display: none;
  @include xsView {
    display: flex;
  }
}

.pc {
  @include smView {
    display: none;
  }
}

@include mdView {
  .main {
    padding-top: 24px;
  }
  .share_title {
    font-size: 32px;
  }
  .share {
    &_btn {
      font-size: 28px;
      min-width: 530px;
    }
  }
  .sns {
    margin-top: 32px;
    > * {
      > * {
        height: 50px;
      }
      &:not(:first-child) {
        margin-left: 36px;
      }
    }
  }
  .c {
    margin-top: 24px;
    font-size: 14px;
  }
}

@include smView {
  .jouro {
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 33%;
    transform: translateY(-100vw / 3 * .7);
    > img {
      width: 100%;
    }
  }

  .ufo {
    bottom: calc(100% + (100vw / 3) * (19 / 15));
    width: 20%;
  }

  .main {
    padding-top: 18px;
  }
  .shibons {
    > * {
      width: calc((100% - 40px) / 3);
      &:nth-child(2n) {
        transform: translateY(0);
      }
    }
  }
  .share_title {
    font-size: 18px;
  }
  .share {
    margin-top: 18px;
    &_btn {
      padding: 8px 16px;
      font-size: 14px;
      min-width: 320px;
    }
  }
  .sns {
    margin-top: 18px;
    > * {
      > * {
        height: 36px;
      }
      &:not(:first-child) {
        margin-left: 18px;
      }
    }
  }
  .c {
    margin-top: 24px;
    font-size: 10px;
    padding: 0 12px;
  }
}

@include xsView {
  .share_title {
    font-size: 18px;
  }
  .share {
    margin-top: 7px;
    &_btn {
      min-width: 150px;
      padding: 8px 55px;
      font-size: var(--font-defalut-xs);
    }
  }
  .sns {
    margin-top: 8px;
    > * {
      > * {
        height: 24px;
      }
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
  .c {
    margin-top: 8px;
  }
}
</style>
