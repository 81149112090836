<template>
  <div :class="$style.container">
    <div :class="$style.flipping_card">
      <div :class="$style.flipping_card_inner">
        <div :class="$style.flipping_card_front">{{ flippingText }}</div>
        <div :class="$style.flipping_card_back">{{ flippingText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlippingCard',
  props: {
    flippingText: {
      type: String,
      default: 'Hover me',
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.flipping_card {
  // width: 100px;
}

.flipping_card_inner {
  position: relative;
  width: 100%;
  height: 30px;
  transform-style: preserve-3d;
  &:hover {
    transition: transform 0.6s cubic-bezier(0.8, -0.65, 0.3, 1.7);
  }
}

.flipping_card_front,
.flipping_card_back {
  position: relative;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  border: 1px solid #000;
  border-radius: 1.5em;
}

.flipping_card_front {
  color: #000;
  background-color: #fff;
}

// 回転したのちの背景色は検討
.flipping_card_back {
  color: #fff;
  background-color: #000;
  transform: rotateX(180deg);
}

.flipping_card:hover .flipping_card_inner {
  transform: rotateX(180deg) translateY(-100%);
}
</style>
