<template>
  <div :class="s.container">
    <div :class="s.deco">
      <img src="/img/pages/guestartists/deco.svg" alt="">
    </div>
    <div :class="s.wrap">
      <div :class="s.logo">
        <img src="/img/pages/guestartists/logo.svg" alt="">
        <div :class="s.logo_sub">
          <img src="/img/pages/guestartists/logo_sub.svg" alt="">
        </div>
      </div>
      <div :class="s.artist_wrap">
        <div
          v-for="artist in artists"
          :key="artist.id"
          :class="[s.artist, s[artist.type]]">
          <div :class="s.img_wrap">
            <div>
              <!-- <div :class="s.img"><img :src="`/img/pages/guestartists/${artist.id}.png`" alt=""></div> -->
              <div :class="[s.img, s.gif]"><img :src="`/img/pages/guestartists/${artist.id}.gif`" alt=""></div>
              <div :class="s.img_logo"><img :src="`/img/pages/guestartists/${artist.id}_logo.png`" alt=""></div>
            </div>
          </div>
          <div :class="s.name">{{ artist.name }}</div>
          <div :class="s.description" v-html="artist.description"/>
          <div :class="s.links">
            <div :class="s.link">
              <a
                :href="artist.link"
                target="_blank">{{ artist.link }}</a>
            </div>
            <div :class="s.sns">
              <a
                :class="s.insta"
                :href="artist.insta"
                target="_blank"><img src="/img/sns/icon_insta.png" alt=""></a>
              <a
                :class="s.x"
                :href="artist.x"
                target="_blank"><img src="/img/sns/icon_x.png" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      artists: [
        {
          id: 'takeichika',
          name: '竹井千佳',
          description: '女性のモチーフを用いて表現するのを主とし、ポップな色彩の可愛らしさとある種の醒めた視線が魅力。TBS『夢なら醒めないで』のスタジオデザイン、安室奈美恵の展覧会『Final Space』公式グッズのイラストを担当するなど幅広く活動。',
          x: 'https://x.com/takeichika?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
          insta: 'https://www.instagram.com/chikatakei/',
          link: 'http://chikatakei.net',
          type: 'right',
        },
        {
          id: 'teradatera',
          name: '寺田てら',
          description: '独自の色彩感覚と独特な世界観に定評のある、Z世代のデジタルクリエイター。MVのアートワークから、ヨウジヤマモトとのアパレルコラボまで幅広い分野で活躍。代表作にまふまふ、ナナヲアカリ『チューリングラブfeat.Sou』やAdo『阿修羅ちゃん』など。',
          x: 'https://x.com/trcoot?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
          insta: 'https://www.instagram.com/trcoot/',
          link: 'https://teradatera.com',
          type: 'left',
        },
        {
          id: 'redfish',
          name: 'REDFISH',
          description: '京都府出身。日本と韓国を中心に、様々なブランドとのコラボレーションやアーティストへのアートワークを手掛けている。アート・音楽・ファッション全て、自身が生み出す世界観 #REDFISHWORLD を通して世界へ発信していく。',
          x: 'https://x.com/i_am_redfish',
          insta: 'https://www.instagram.com/i_am_redfish/',
          // link: 'https://redfish-world.com',
          type: 'right',
        },
      ],
    };
  },
};
</script>

<style lang="scss" module="s">
.container {
  background-color: var(--primary-pink);
  background-image: url('/img/pages/guestartists/artist_bg_pc.svg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 50% 0;
  border-top: 2px solid var(--primary-black);
  position: relative;
}
.wrap {
  padding: 124px 0 0;
}
.logo {
  margin: 0 auto;
  width: 80%;
  &_sub {
    margin-top: 5px;
    position: relative;
    width: 86%;
    margin: 5px auto 0;
  }
}
.artist_wrap {
  padding: calc(100% / 3 * .52 * (67 / 53) / 2) 32px 0;
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.artist {
  position: relative;
  margin-top: 112px;
  background-color: var(--secondary-pink);
  border: 1px solid var(--primary-black);
  border-bottom: none;
  border-radius: 500px 500px 0 0;
  width: calc((100% - 24px * 2) / 3);
  padding: 100px 42px;
}

.img_wrap {
  width: 52%;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 23%);
  pointer-events: none;
  > * {
    position: relative;
  }
  .img_logo {
    position: absolute;
    top: 28%;
    right: 80%;
    width: 50%;
  }
  .gif {
    position: relative;
    left: -33%;
    width: 167%;
    transform: translateY(10%);
  }
}
.name {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
}
.description {
  padding-top: 10px;
  font-weight: 600;
  font-size: var(--font-default);
}
.links {
  padding-top: 15px;
  margin: 0 auto;
  text-align: center;
  .link {
    font-size: 24px;
  }
  .sns {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
      &:not(:first-child) {
        margin-left: 7px;
      }
    }
    img {
      object-fit: contain;
    }
    .x {
      width: 33px;
      height: 33px;
    }
    .insta {
      width: 35px;
      height: 35px;
    }
  }
}

.deco {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@include mdView {
  .name {
    font-size: 24px;
  }
  .description {
    font-size: var(--font-default-md);
  }
  .links {
    .link {
      font-size: 16px;
    }
  }
}

@include smView {
  .container {
    background-image: url('/img/pages/guestartists/artist_bg_sp.svg');
    background-repeat: repeat-y;
  }
  .wrap {
    padding: 24px 0 20vw;
  }
  .artist_wrap {
    display: block;
    padding: 15px 0 0;
    .artist {
      width: 95%;
      padding: 120px 42px;
      border: 1px solid var(--primary-black);

      $img-w: 110px;
      .img_wrap {
        width: $img-w;
        transform: translate(0, 40px);
        left: auto;
      }
      &.right {
        margin-left: auto;
        padding: 16px 38px 24px 50px;
        border-right: none;
        border-radius: 200px 0 0 200px;
        .img_wrap {
          left: 50px;
          .gif {
            position: relative;
            top: 20px;
          }
        }
        .img_logo {
          left: 110%;
          top: 60%;
        }
        .name {
          text-align: start;
          padding-left: calc($img-w + 20px);
        }
      }
      &.left {
        margin-right: auto;
        padding: 16px 50px 24px 38px;
        border-left: none;
        border-radius: 0 200px 200px 0;
        .img_wrap {
          right: 50px;
          .gif {
            position: relative;
            top: 20px;
          }
        }
        .img_logo {
          right: 100%;
          top: 60%;
        }
        .name {
          text-align: end;
          padding-right: calc($img-w + 20px);
        }
      }
    }
  }
  .name {
    font-size: 18px;
  }
  .description {
    padding-top: 10px;
    font-size: var(--font-default-sm);
  }

  .links {
    padding-top: 15px;
    margin: 0 auto;
    .link {
      font-size: 12px;
    }
    .sns {
      margin-top: 8px;
      > * {
        &:not(:first-child) {
          margin-left: 7px;
        }
      }
      .x {
        width: 22px;
        height: 22px;
      }
      .insta {
        width: 24px;
        height: 24px;
      }
    }
  }
  .deco {
    display: block;
  }
}

@include xsView {
  .description {
    font-size: var(--font-default-xs);
  }
}

$name-logo-w: 70%, 95%, 70%;
@for $i from 1 through 3 {
  .artist {
    &:nth-child(#{$i}) {
      .img_logo {
        width: nth($name-logo-w, $i);
      }
    }
  }
}
</style>
