<template>
  <div :class="s.wrap">
    <div :class="s.bg" id="gallery-bg" />
    <div :class="s.container">
      <div :class="s.logo">
        <img src="/img/pages/gallery/gallery.png" alt="">
        <div :class="[s.syugo, s.pc]"><img src="/img/pages/gallery/syugo.png" alt=""></div>
        <div :class="[s.syugo, s.sp]"><img src="/img/pages/gallery/syugo_sp.png" alt=""></div>
      </div>
      <div :class="s.content_wrap">
        <!-- ポスター -->
        <div :class="s.posters">
          <div :class="s.poster_wrap">
            <div v-for="n of 4" :key="n">
              <div
                v-for="m of 3"
                :key="m"
                :class="s.poster"><img :src="`/img/pages/gallery/poster/poster_${m}.png`" :alt="`poster_${m}`"></div>
              <!-- <div
                v-for="(poster, i) in posters"
                :key="i"
                :class="s.poster"><img :src="poster.url" :alt="poster.label"></div> -->
            </div>
          </div>
        </div>
        <!-- バーチャル背景 -->
        <div :class="s.vbgs">
          <div :class="s.vbg_wrap">
            <div v-for="n of 4" :key="n">
              <div :class="s.vbg" ><img src="/img/pages/gallery/poster/vbg_1.png" alt="Image 1"></div>
              <div :class="s.vbg" ><img src="/img/pages/gallery/poster/vbg_2.png" alt="Image 1"></div>
              <div :class="s.vbg" ><img src="/img/pages/gallery/poster/vbg_3.png" alt="Image 1"></div>
              <div :class="s.vbg" ><img src="/img/pages/gallery/poster/vbg_4.png" alt="Image 1"></div>
            </div>
          </div>
        </div>
        <!-- ボタンなど -->
        <div :class="s.content">
          <div :class="s.toge">
            <img src="/img/effects/toge_yellow.png" alt="">
          </div>
          <div :class="s.text_wrap">
            <div :class="s.text">自由に<br>ダウンロード<br>＆<br>印刷して使ってね！</div>
            <!-- accordion -->
            <div :class="s.accordion_container">
              <div :class="s.accordion_standard"/>
              <div
                @click="triggerClicked"
                :class="s.btn">
                <div>ダウンロードはコチラ</div>
              </div>
              <div :class="s.accordion_wrap">
                <img :class="s.giza" src="/img/effects/giza.svg" alt="">
                <div
                  id="accordion-content-gallery"
                  :class="[s.accordion, flag.showPosters ? s.isActive : '']">
                  <div :class="s.accordion_inner" :style="`max-height: ${accordionMaxH}px;`">
                    <ul :class="s.accordion_poster">
                      <li v-for="(poster, i) in posters" :key="i">
                        <div :class="s.poster_title">ポスター: <span class="nowrap">{{ poster.label }}</span></div>
                        <img :src="poster.url" alt="poster">
                        <a
                          :class="s.btn"
                          :href="poster.downloadUrl"
                          :download="`ポスター(${poster.label})`">
                          <div>ダウンロード</div>
                        </a>
                      </li>
                    </ul>
                    <ul :class="s.accordion_vbg">
                      <li v-for="(vbg, i) in vbgs" :key="i">
                        <div :class="s.vbg_title">バーチャル背景: <span class="nowrap">{{ vbg.label }}</span></div>
                        <img
                          :src="vbg.url" alt="vbg">
                        <a
                          :class="s.btn"
                          :href="vbg.downloadUrl"
                          :download="`バーチャル背景(${vbg.label})`">
                          <div>ダウンロード</div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="s.vision">
        <div :class="s.vision_movie_wrap">
          <video
            :class="s.vision_movie"
            src="/img/movie/choiku_ooh.mp4"
            alt="渋谷駅横ビジョン"
            width="100%"
            controls
            autoplay
            muted
            loop
            playsinline />
          <img
            :class="s.vision_movie_frame"
            src="/img/effects/movie_frame.svg" alt="">
        </div>
        <div :class="s.vision_text_wrap">
          <div :class="s.vision_text">
            <div :class="s.vision_text_title">渋谷駅横ビジョン</div>
            <div :class="s.vision_text_date">7/29〜8/11</div>
          </div>
        </div>
      </div>
      <!-- director -->
      <div :class="s.director_wrap">
        <div :class="s.separator"><img src="/img/effects/dot_separator_sp.svg" alt=""></div>
        <div :class="s.director_logo">
          <img src="/img/pages/gallery/director_logo.svg" alt="">
        </div>
        <div>
          <div :class="s.director_img_wrap">
            <!-- <div :class="s.director_img"><img src="/img/pages/gallery/kawasakirui.png" alt=""></div> -->
            <div :class="[s.director_img, s.gif]"><img src="/img/pages/gallery/kawasakirui.gif" alt=""></div>
            <div :class="s.director_img_logo"><img src="/img/pages/gallery/logo_kawasakirui.svg" alt=""></div>
          </div>
          <div :class="s.director_text">
            <div :class="s.director_name">川﨑塁</div>
            <div :class="s.director_title">アートディレクター ／  グラフィックデザイナー</div>
            <div :class="s.director_detail">アートやファッション、エンタメカルチャーを軸に、「楽しい」や「面白い」の感情に触 れるビジュアルで、カテゴリーやジャンルを越えたデザインを手がける。本プロジェクト では、ロゴ開発から全体のアートディレクションまでを担当。</div>
            <div :class="s.director_sns">
              <a
                :class="s.director_sns_insta"
                href="https://www.instagram.com/ruikawasaki_/"
                target="_blank"><img src="/img/sns/icon_insta.png" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      flag: {
        showPosters: false,
      },
      accordionMaxH: 0,
      posters: [
        {
          label: 'REDFISH',
          url: '/img/pages/gallery/poster/poster_1.png',
          downloadUrl: '/img/pages/gallery/poster_download/poster_1.png',
        },
        {
          label: '竹井千佳',
          url: '/img/pages/gallery/poster/poster_2.png',
          downloadUrl: '/img/pages/gallery/poster_download/poster_2.png',
        },
        {
          label: '寺田てら',
          url: '/img/pages/gallery/poster/poster_3.png',
          downloadUrl: '/img/pages/gallery/poster_download/poster_3.png',
        },
        {
          label: 'コンセプトA',
          url: '/img/pages/gallery/poster/poster_4.png',
          downloadUrl: '/img/pages/gallery/poster_download/poster_4.png',
        },
        {
          label: 'コンセプトB',
          url: '/img/pages/gallery/poster/poster_5.png',
          downloadUrl: '/img/pages/gallery/poster_download/poster_5.png',
        },
      ],
      vbgs: [
        {
          label: 'ビビッド',
          url: '/img/pages/gallery/poster/vbg_1.png',
          downloadUrl: '/img/pages/gallery/poster_download/vbg_1.png',
        },
        {
          label: 'パステル',
          url: '/img/pages/gallery/poster/vbg_2.png',
          downloadUrl: '/img/pages/gallery/poster_download/vbg_2.png',
        },
        {
          label: 'キャラ集合',
          url: '/img/pages/gallery/poster/vbg_3.png',
          downloadUrl: '/img/pages/gallery/poster_download/vbg_3.png',
        },
        {
          label: '未来予報',
          url: '/img/pages/gallery/poster/vbg_4.png',
          downloadUrl: '/img/pages/gallery/poster_download/vbg_4.png',
        },
      ],
    };
  },
  created() {},
  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      this.bgInit();
    });
    resizeObserver.observe(document.getElementById('gallery-bg'));
  },
  methods: {
    bgInit() {
      const bgElem = document.getElementById('gallery-bg');
      if (bgElem) bgElem.innerHTML = '';
      const elemH = bgElem?.clientHeight;
      const elemW = bgElem?.clientWidth;
      const numCirclesHorizontally = 3;
      const circleSpacing = 0.6; // 円の直径との割合
      const circleDiameter = elemW / (numCirclesHorizontally * circleSpacing + numCirclesHorizontally);
      const circleSpacePx = circleDiameter * circleSpacing;
      const numRows = Math.ceil(elemH / (circleDiameter + circleSpacePx));

      for (let row = 0; row < numRows + 1; row += 1) {
        for (let col = 0; col < numCirclesHorizontally + 1; col += 1) {
          const circle = document.createElement('div');
          circle.classList.add(this.s.circle);
          circle.style.width = `${circleDiameter}px`;
          circle.style.height = `${circleDiameter}px`;

          const offsetX = (col * (circleDiameter + circleSpacePx));
          const offsetY = (row * (circleDiameter + circleSpacePx));
          const offsetRow = (row % 2 === 0) ? circleDiameter + circleSpacePx / 2 : (circleDiameter) / 2;

          circle.style.left = `${offsetX - offsetRow}px`;
          circle.style.top = `${offsetY}px`;

          if (row === numRows - 2 && col === numCirclesHorizontally - 2) {
            const coin = document.createElement('div');
            coin.classList.add(this.s.coin);
            coin.style.width = `${circleDiameter}px`;
            coin.style.height = `${circleDiameter}px`;
            coin.style.left = `${offsetX - offsetRow - circleDiameter / 3}px`;
            coin.style.top = `${offsetY - circleDiameter / 3}px`;
            bgElem.appendChild(coin);
          }

          bgElem.appendChild(circle);
        }
      }
    },

    triggerClicked() {
      this.flag.showPosters = !this.flag.showPosters;
      if (this.flag.showPosters) {
        const winH = window.innerHeight;
        const accordionElem = document.getElementById('accordion-content-gallery');
        const elemPos = accordionElem?.getClientRects()[0].top;
        if (!accordionElem || !winH) return;
        const bottomLen = winH - elemPos;
        this.accordionMaxH = bottomLen - 20;
      } else {
        this.accordionMaxH = 0;
      }
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  border-top: 2px solid var(--primary-black);
  padding: 8% 0 0;
  background-color: #fff; /* 背景色を設定 */
  overflow: hidden;
  position: relative;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  .circle {
    position: absolute;
    border-radius: 50%;
    background-color: var(--primary-green);
    border: 1px solid var(--primary-black);
  }
  .coin {
    position: absolute;
    background-image: url('/img/items/coin.gif');
    background-size: contain;
    transform: rotate(-45deg);
  }
}

.container {
  width: 100%;
  position: relative;
  z-index: 2;
}

.logo {
  margin: 0 auto;
  width: 65%;
  .syugo {
    width: 75%;
    display: block;
    margin: -5vw auto 0;
    transform: translateX(-4%);
  }
}


$poster-w: 100vw * .28;
.posters {
  z-index: 2;
  margin-top: -5%;
  width: 100%;
  position: relative;
  transform-origin: top right;
  transform: rotate(-10deg);
}
.poster_wrap {
  display: flex;
  animation: scroll 20s linear infinite;
  width: calc($poster-w * 12);
  background-color: var(--primary-pink);

  > * {
    display: flex;
  }
  .poster {
    border: 1px solid var(--primary-black);
    border-right: none;
    display: inline-block;
    width: $poster-w;
  }
}

.vbgs {
  z-index: 1;
  margin-top: 25%;
  width: 100%;
  position: relative;
  transform: rotate(10deg);
}
.vbg_wrap {
  display: flex;
  animation: scroll-reverse 20s linear infinite;
  width: calc($poster-w * 12);
  background-color: var(--primary-yellow);

  > * {
    display: flex;
  }
  .vbg {
    > img { object-fit: cover; }
    display: inline-block;
    height: calc($poster-w * (9 / 16));
    width: $poster-w;
    border: 1px solid var(--primary-black);
    border-right: none;
  }
}

.content {
  &_wrap {
    position: relative;
    margin-bottom: 10%;
    z-index: 1;
  }

  position: absolute;
  z-index: 3;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .toge {
    display: block;
    margin: 0 auto;
    width: 66%;
    max-width: 670px;
    }
  .text_wrap {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 15px));
  }
  .text {
    font-size: 38px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 22px;
  }
}

.btn {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  display: inline-block;
  > * {
    position: relative;
    z-index: 10;
    margin: 0 auto;
    font-size: 38px;
    padding: 20px 40px;
    background-color: var(--primary-white);
    display: inline-block;
    font-weight: 700;
    border: 2px solid var(--primary-black);
    border-radius: 50px;
  }
  &::before {
    position: absolute;
    z-index: 5;
    top: 7px;
    left: 7px;
    content: '';
    width: 100%;
    height: 100%;
    border: 2px solid var(--primary-black);
    background-color: var(--primary-yellow);
    border-radius: 50px;
  }
}

$giza-w: 83vw;
$giza-h: calc($giza-w * 13 / 300);
.accordion {
  &_container {
    position: relative;
  }
  &_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  position: relative;
  top: -1px;
  z-index: 2;
  width: 73.5%;
  margin: 0 auto;
  max-height: 0;

  &_inner {
    background-color: var(--primary-white);
    width: 100%;
    height: 100%;
    max-height: 0;
    padding: 0 25%;
    overflow: scroll;
    transition: all .3s;
    border: none;
  }
  &::before {
    z-index: 50;
    content: "";
    position: absolute;
    top: calc(-1 * ($giza-h) + 1px); /* ギザギザの高さを調整 */
    left: 0;
    width: 100%;
    height: $giza-h; /* ギザギザの高さ */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 25" preserveAspectRatio="none"><polygon fill="%23fff" points="0,25 50,0 100,25"/></svg>');
    background-repeat: repeat-x;
    background-size: calc($giza-w / 12) 100%;
    background-position: bottom center;
    opacity: 0;
    transition: all .3s;
  }

  &.isActive {
    .accordion_inner {
      max-height: 1000px;
      padding: 15% 25%;
      border-width: 1px;
      border-style: solid;
      border-color: transparent var(--primary-black) var(--primary-black);
    }
    &::before {
      opacity: 1;
    }
  }
}
.giza {
  position: relative;
  display: block;
  margin: 0 auto;
  width: $giza-w !important;
  z-index: 3;
}

.accordion_poster {
  > li {
    font-size: 38px;
    font-weight: 700;
    &:not(:first-child) {
      margin-top: 72px;
    }
    .poster_title {
      margin-bottom: 24px;
    }
    .btn {
      margin-top: 30px;
    }
    > img {
      border: 1px solid var(--primary-black);
    }
  }
}
.accordion_vbg {
  margin-top: 144px;
  > li {
    font-size: 38px;
    font-weight: 700;
    &:not(:first-child) {
      margin-top: 72px;
    }
    .vbg_title {
      margin-bottom: 24px;
    }
    .btn {
      margin-top: 30px;
    }
    > img {
      border: 1px solid var(--primary-black);
    }
  }
}

.vision {
  position: relative;
  margin: 300px auto 0;
  width: 80%;
  margin-bottom: 10%;

  &_movie_wrap {
    position: relative;
    height: fit-content;
  }

  &_movie {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 89%;
    transform: translate(-50.55%, -51%);
    margin: 0 auto;
  }

  &_movie_frame {
    position: relative;
    pointer-events: none;
  }
  &_text_wrap {
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -2%);
  }
  &_text {
    position: relative;
    font-weight: bold;
  }
  &_text_title {
    position: relative;
    font-size: 62px;
    border: 1px solid var(--primary-black);
    border-radius: 100px;
    padding: 12px 72px;
    white-space: nowrap;
    background-color: var(--primary-yellow);
    transform: rotate(-5deg);
  }
  &_text_date {
    font-size: 56px;
    padding: 8px 36px;
    border: 1px solid var(--primary-black);
    border-radius: 100px;
    position: absolute;
    bottom: 100%;
    left: 0;
    transform: rotate(-20deg) translate(-50%, -10%);
    background-color: var(--primary-white);
  }
  
}

.comingsoon_text {
  margin: 0 auto;
  width: fit-content;
  transform: translateY(22%);
  > * {
    &:nth-child(1) {
      font-size: 42px;
      font-weight: 700;
      transform: rotate(10deg);
      text-align: center;
      margin-left: 5%;
    }
    &:nth-child(2) {
      width: 400px;
      margin-right: 20%;
      transform: rotate(-10deg);
      margin-top: -20%;
    }
  }
}

.director {
  &_wrap {
    .separator {
      margin: 0 auto;
      width: 86%;
    }
  }
  &_logo {
    width: 77%;
    margin: 10% auto 20px;
  }
  &_img_wrap {
    width: 15%;
    margin: 0 auto;
    position: relative;
    .gif {
      width: 170%;
      position: relative;
      left: 50%;
      transform: translate(-50%, 30%);
    }
  }
  &_img_logo {
    width: 100%;
    position: absolute;
    top: 30%;
    right: 85%;
  }
  &_text {
    margin: 0 auto;
    width: 30%;
    border-radius: 300px 300px 0 0;
    background-color: rgb(255, 238, 89);
    padding: 7% 33px 25%;
    border: 1px solid var(--primary-black);
    border-bottom: none;
  }
  &_name {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  &_title {
    font-size: var(--font-default);
    font-weight: 600;
    margin-top: 8px;
    text-align: center;
  }
  &_detail {
    margin-top: 20px;
    font-size: var(--font-default);
    font-weight: 600;
  }
  &_sns {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: contain;
    }
    &_insta {
      width: 24px;
      height: 24px;
    }
  }
}

@include mdView {
  .btn {
    > * {
      padding: 12px 30px;
      font-size: 16px;
    }
  }
  .content {
    .text {
      font-size: 28px;
      margin-bottom: 15px;
    }
  }
  .accordion_poster {
    > li {
      font-size: 20px;
      &:not(:first-child) {
        margin-top: 56px;
      }
      .poster_title {
        margin-bottom: 16px;
      }
      .btn {
        margin-top: 24px;
      }
    }
  }
  .accordion_vbg {
    margin-top: 112px;
    > li {
      font-size: 20px;
      &:not(:first-child) {
        margin-top: 56px;
      }
      .vbg_title {
        margin-bottom: 16px;
      }
      .btn {
        margin-top: 24px;
      }
    }
  }
  .comingsoon_text {
    > * {
      &:nth-child(1) {
        font-size: 36px;
        margin-left: 5%;
      }
      &:nth-child(2) {
        width: 300px;
        margin-right: 20%;
        margin-top: -20%;
      }
    }
  }

  .vision {
    margin-top: 200px;
    &_text_title {
      padding: 10px 60px;
      font-size: 42px;
    }
    &_text_date {
      font-size: 36px;
    }
  }

  .director {
    &_title {
      font-size: var(--font-default-md);
    }
    &_detail {
      font-size: var(--font-default-md);
    }
  }
}

@include smView {
  .wrap {
    padding: 30px 0 44%;
  }
  .logo {
    width: 90%;
    .syugo {
      width: 94%;
      display: block;
      margin: -25px auto 0;
      transform: translateX(-4%);
    }
  }
  .posters {
    margin-top: 0;
  }
  .vbgs {
    margin-top: 40%;
  }
  .btn {
    > * {
      padding: 8px 12px;
      font-size: 16px;
      border-width: 1px;
    }
    &::before {
      border-width: 1px;
      top: 4px;
      left: 4px;
    }
  }
  .content {
    .text {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
  .accordion {
    &.isActive {
      .accordion_inner {
        padding: 15% 60px;
      }
    }
  }
  .accordion_poster {
    > li {
      font-size: 16px;
      &:not(:first-child) {
        margin-top: 48px;
      }
      .poster_title {
        margin-bottom: 12px;
      }
      .btn {
        margin-top: 16px;
      }
    }
  }
  .accordion_vbg {
    > li {
      font-size: 16px;
      &:not(:first-child) {
        margin-top: 48px;
      }
      .vbg_title {
        margin-bottom: 12px;
      }
      .btn {
        margin-top: 16px;
      }
    }
  }
  .comingsoon_text {
    > * {
      &:nth-child(1) {
        font-size: 24px;
        margin-left: 5%;
      }
      &:nth-child(2) {
        width: 200px;
        margin-right: 20%;
        margin-top: -20%;
      }
    }
  }
  .vision {
    margin-top: 200px;
    &_text_title {
      padding: 10px 40px;
      font-size: 28px;
    }
    &_text_date {
      padding: 6px 16px;
      font-size: 22px;
      transform: rotate(-20deg) translate(-45%, 5%);
    }
  }
  .director {
    &_logo {
      margin: 5% auto 20px;
    }
    &_img_wrap {
      width: 117px;
      .gif {
        transform: translate(-50%, 20%);
      }
    }
    &_img_logo {
      top: 35%;
      transform: translateY(7%);
    }
    &_text {
      margin-top: -25px;
      margin-left: auto;
      margin-right: 0;
      width: 95%;
      border-radius: 300px 0 0 300px;
      background-color: var(--primary-yellow);
      padding: 33px;
      border: 1px solid var(--primary-black);
      border-right: none;
    }
    &_name {
      font-size: 18px;
      text-align: center;
      padding-right: 5vw;
    }
    &_title {
      margin-top: 6px;
      font-size: var(--font-default-sm);
      font-weight: 600;
    }
    &_detail {
      margin-top: 12px;
      font-size: var(--font-default-sm);
      font-weight: 600;
    }
    &_sns {
      margin-top: 15px;
      padding-right: 5vw;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: contain;
      }
      &_insta {
        width: 24px;
        height: 24px;
      }
    }
  }

}

@include xsView {
  .content {
    .text {
      font-size: 12px;
    }
  }
  .btn {
    > * {
      font-size: 12px;
    }
  }
  .accordion {
    &.isActive {
      .accordion_inner {
        padding: 15% 30px;
      }
    }
  }
  .accordion_poster {
    > li {
      font-size: 14px;
      .poster_title {
        margin-bottom: 8px;
      }
      .btn {
        margin-top: 8px;
      }
    }
  }
  .accordion_vbg {
    margin-top: 48px;
    > li {
      font-size: 14px;
      .vbg_title {
        margin-bottom: 8px;
      }
      .btn {
        margin-top: 12px;
      }
    }
  }
  .comingsoon_text {
    > * {
      &:nth-child(1) {
        font-size: 18px;
        margin-left: 5%;
      }
      &:nth-child(2) {
        width: 150px;
        margin-right: 20%;
        margin-top: -20%;
      }
    }
  }
  .vision {
    margin-top: 150px;
    &_text_title {
      padding: 8px 32px;
      font-size: 20px;
    }
    &_text_date {
      padding: 4px 12px;
      font-size: 18px;
      transform: rotate(-20deg) translate(-40%, 5%);
    }
  }
  .director {
    &_img_wrap {
      transform: translateY(0);
    }
    &_title {
      font-size: var(--font-default-xs);
    }
    &_detail {
      font-size: var(--font-default-xs);
    }
  }
}

.pc {
  @include smView {
    display: none !important;
  }
}

.sp {
  display: none !important;
  @include smView {
    display: block !important;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(calc(-100% * 2 / 4));
  }
  100% {
    transform: translateX(calc(-100% * 1 / 4));
  }
}
@keyframes scroll-reverse {
  0% {
    transform: translateX(calc(-100% * 1 / 4));
  }
  100% {
    transform: translateX(calc(-100% * 2 / 4));
  }
}
</style>
