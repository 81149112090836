<template>
  <div
    :class="[
      s.wrap,
      flag.isEnding ? s.clipped : '',
    ]"
    v-if="flag.isShow">
    <div :class="[s.loading_container, s.top]">
      <div :class="s.loading_text">
        <span v-for="(letter, i) in 'Loading...'" :key="i">{{ letter }}</span>
      </div>
    </div>
    <ul :class="s.areas">
      <li v-for="n in [2, 1, 3]" :key="n">
        <img
          v-if="showNums.includes(n)"
          :src="`/img/loading/seebon_${n}.gif`" alt="">
      </li>
    </ul>
    <div :class="[s.loading_container, s.bottom]">
      <div :class="s.loading_text">
        <span v-for="(letter, i) in 'Loading...'" :key="i">{{ letter }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showNums: [],
      timeoutId: null,
      flag: {
        isShow: true,
        isEnding: false,
      },
    };
  },
  created() {
    // this.timeoutId = setTimeout(() => this.showSeebon(), 700);
    this.showSeebon();
  },
  mounted() {
    if (location.pathname === '/') {
      const body = document.getElementsByTagName('body');
      if (body?.[0]?.style) body[0].style['overflow-y'] = 'hidden';
    }
  },
  methods: {
    showSeebon() {
      this.showNums.push(this.showNums.length + 1);
      if (this.showNums.length < 3) this.timeoutId = setTimeout(() => this.showSeebon(), 700);
      else this.timeoutId = setTimeout(() => this.endLoading(), 1500);
    },
    endLoading() {
      this.flag.isEnding = true;
      this.timeoutId = setTimeout(() => {
        this.flag.isShow = false;
        const body = document.getElementsByTagName('body');
        body[0].style['overflow-y'] = 'scroll';
      }, 1000);
    },
  },
};
</script>

<style lang="scss" module="s">
$fs: 36px;
$fs-md: 30px;
$fs-sm: 24px;
$fs-xs: 18px;
.wrap {
  position: fixed;
  background-color: var(--primary-white);
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  clip-path: circle(100%);
  font-size: $fs;
  &.clipped {
    transition: all 1s;
    clip-path: circle(0%);
    .areas {
      > li {
        > img {
          transition: all .5s;
          // transform: scale(0);
        }
      }
    }
    .loading_container {
      opacity: 0;
    }
  }
}
.areas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 600px;
  > li {
    width: calc(100% / 3);
    > img {
      width: 100%;
    }
  }
}

.loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s;
  opacity: 1;

  .loading_text {
    > span {
      display: inline-block;
      animation: bounce 1s infinite;
      font-weight: 700;

      &:nth-child(1) { animation-delay: 0s; }
      &:nth-child(2) { animation-delay: 0.1s; }
      &:nth-child(3) { animation-delay: 0.2s; }
      &:nth-child(4) { animation-delay: 0.3s; }
      &:nth-child(5) { animation-delay: 0.4s; }
      &:nth-child(6) { animation-delay: 0.5s; }
      &:nth-child(7) { animation-delay: 0.6s; }
      &:nth-child(8) { animation-delay: 0.9s; }
      &:nth-child(9) { animation-delay: 1s; }
      &:nth-child(10) { animation-delay: 1.1s; }

    }
  }
  &.top {
    .loading_text {
      > span {
        &:nth-child(2n) { color: var(--primary-green);}
        &:nth-child(2n+1) { color: var(--primary-pink);}
      }
    }
  }
  &.bottom {
    .loading_text {
      > span {
        &:nth-child(2n) { color: var(--primary-pink);}
        &:nth-child(2n+1) { color: var(--primary-green);}
      }
    }
  }
}

@include mdView {
  .wrap {
    font-size: $fs-md;
  }
}
@include smView {
  .wrap {
    font-size: $fs-sm;
  }
}
@include xsView {
  .wrap {
    font-size: $fs-xs;
  }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}
</style>
