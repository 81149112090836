<template>
  <div :class="s.wrap">
    <div :class="[s.absolute_wrap, s.front]">
      <div :class="s.absolute_container">
        <div :class="[s.a_sanzaingen, s.pc]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_a.svg" alt=""></div>
            <div :class="s.gif"><img src="/img/characters/gifs/sanzaingen_2.gif" alt=""></div>
          </div>
        </div>
        <div :class="[s.a_sensaichijiku, s.pc]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_b.svg" alt=""></div>
            <div :class="s.gif"><img src="/img/characters/gifs/sensaichijiku.gif" alt=""></div>
          </div>
        </div>
        <div :class="[s.a_kosupapurika, s.pc]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_d.svg" alt=""></div>
            <div :class="s.gif"><img src="/img/characters/gifs/kosupapurika_2.gif" alt=""></div>
          </div>
        </div>
        <div :class="[s.a_papurikapurple, s.pc]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_a.svg" alt=""></div>
            <div :class="s.gif"><img src="/img/characters/gifs/kosupapurika_4.gif" alt=""></div>
          </div>
        </div>
        <div :class="[s.a_papurikapink, s.pc]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_b.svg" alt=""></div>
            <div :class="s.gif"><img src="/img/characters/gifs/kosupapurika_3.gif" alt=""></div>
          </div>
        </div>
        <div :class="[s.a_kosupapurika, s.sp]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_e.svg" alt=""></div>
            <div :class="s.gif"><img src="/img/characters/gifs/kosupapurika_2.gif" alt=""></div>
          </div>
        </div>
        <div :class="[s.a_sensaichijiku, s.sp]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_a.svg" alt=""></div>
            <div :class="s.gif"><img src="/img/characters/gifs/sensaichijiku.gif" alt=""></div>
          </div>
        </div>
        <div :class="[s.a_sanzaingen, s.sp]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_f.svg" alt=""></div>
            <div :class="s.gif"><img src="/img/characters/gifs/sanzaingen_2.gif" alt=""></div>
          </div>
        </div>
        <div :class="[s.a_chekeradish, s.sp]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_d.svg" alt=""></div>
            <div :class="s.gif"><img src="/img/characters/gifs/chiekiradeisshu.gif" alt=""></div>
          </div>
        </div>
      </div>
    </div>
    <div :class="[s.absolute_wrap, s.back]">
      <div :class="s.absolute_container">
        <div :class="[s.a_chekeradish, s.pc]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_e.svg" alt=""></div>
            <div :class="s.gif"><img src="/img/characters/gifs/chiekiradeisshu.gif" alt=""></div>
          </div>
        </div>
        <div :class="s.a_ganbarukkora">
          <div :class="s.a_wrap">
            <div :class="s.gif"><img src="/img/characters/gifs/ganbarukkora_2.gif" alt=""></div>
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_e.svg" alt=""></div>
          </div>
        </div>
        <div :class="[s.a_yo, s.sp]">
          <div :class="s.a_wrap">
            <div :class="s.kana"><img src="/img/pages/futureforecast/kana_d.svg" alt=""></div>
          </div>
        </div>
      </div>
    </div>
    <div :class="s.content">
      <div :class="s.choikuse_title">
        <ChoikuseTitle />
      </div>

      <div :class="s.text_wave_wrap">
        <div :class="s.text_wave">あなたのくせタイプから<span :class="s.half">、</span>勝手な未来を予報。</div>
        <div :class="s.text_wave">15の質問に答えて<span :class="s.half">、</span>“ちょいく<span :class="s.narrow">せ</span>キ<span :class="s.narrow">ャ</span>ラ”に出会おう<span :class="s.space_right">!</span></div>
      </div>
      <!-- 未来予報ボタン -->
      <div :class="s.start_wrap">
        <div :class="[s.toge, s.show]"></div>
        <div :class="s.start_content">
          <div :class="s.start_character">
            <img src="/img/pages/futureforecast/shibon.gif" alt="">
          </div>
          <router-link to="/miraiyohou/" :class="s.start_btn">
            <dot-bg-btn>未来予報スタート<span :class="s.italic">!!</span></dot-bg-btn>
          </router-link>
        </div>
      </div>

      <div :class="s.cloud_box">
        <p :class="s.cloud_box_title">
          あなたの<br>キャラクターは何％？
        </p>
        <div :class="s.cloud_box_subtitle">[{{ addComma(totalCount) }}]人が受けた結果…</div>
        <ul :class="s.statistics">
          <li v-for="(type, i) in types" :key="i">
            <div :class="[s.statistics_img, s[type.type]]"><img :src="type.img" alt=""></div>
            <div :class="type.name === 'secret' ? s.statistics_secret : ''">{{ type.name === 'secret' ? '？' : type.label }}</div>
            <div :class="s.statistics_arrow"/>
            <div :class="s.statistics_ratio">{{ type.ratio }}%</div>
          </li>
        </ul>
        <div :class="s.bubble_container">
          <div :class="s.bubble_wrap">
            <img :class="s.kiwi" src="/img/characters/gifs/kenkiwi.gif" alt="">
            <img :class="s.pc" src="/img/pages/futureforecast/secret_bubble_pc.svg" alt="">
            <img :class="s.sp" src="/img/pages/futureforecast/secret_bubble_sp.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DotBgBtn,
  ChoikuseTitle,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'FutureForecast',
  mixins: [cf],
  components: {
    DotBgBtn,
    ChoikuseTitle,
  },
  props: {},
  data() {
    return {
      totalCount: 0,
      types: [
        {
          type: 'enf',
          label: 'がんばルッコラ',
          ratio: 0,
          img: '/img/characters/png/ganbarukkora.png',
          gif: '/img/characters/gifs/ganbarukkora.gif',
        },
        {
          type: 'isf',
          label: 'さんざインゲン',
          ratio: 0,
          img: '/img/characters/png/sanzaingen.png',
          gif: '/img/characters/gifs/sanzaingen.gif',
        },
        {
          type: 'est',
          label: 'コスパプリカ',
          ratio: 0,
          img: '/img/characters/png/kosupapurika.png',
          gif: '/img/characters/gifs/kosupapurika.gif',
        },
        {
          type: 'esf',
          label: 'チェケラディッシュ',
          ratio: 0,
          img: '/img/characters/png/chiekeradeisshu.png',
          gif: '/img/characters/gifs/chiekiradeisshu.gif',
        },
        {
          type: 'int',
          label: 'けんキウイ',
          ratio: 0,
          img: '/img/characters/png/kenkiwi.png',
          gif: '/img/characters/gifs/kenkiwi.gif',
        },
        {
          type: 'inf',
          label: 'せんさイチジク',
          ratio: 0,
          img: '/img/characters/png/sensaichijiku.png',
          gif: '/img/characters/gifs/sensaichijiku.gif',
        },
        {
          type: 'ist',
          name: 'secret',
          label: 'ミステリコリス',
          ratio: 0,
          img: '/img/characters/png/secret.png',
          gif: '/img/characters/gifs/secret.gif',
        },
      ],
    };
  },
  created() {
    this.getAnswers();
  },
  methods: {
    getAnswers() {
      this.axios({
        method: 'GET',
        url: '/v1/diagnosis/getAnswers/',
      })
        .then((response) => {
          const res = response.data;
          this.setCounts(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setCounts(data) {
      if (!data?.pageCounts) return;
      this.totalCount = data.totalDiagnosisCount;
      this.types.forEach((type) => {
        if (Number(data.pageCounts[type.type] && Number(data.totalDiagnosisCount))) {
          type.ratio = Math.round((data.pageCounts[type.type] / data.totalDiagnosisCount) * 100);
        }
      });
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  background-color: var(--primary-green);
  padding-bottom: 330px;
  padding-top: 134px;
  text-align: center;
  position: relative;
  @include smView {
    padding-top: 20px;
  }
}
.absolute_wrap {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &.front {
    z-index: 3;
  }
  &.back {
    z-index: 1;
    background-image:
      url('/img/deco/deco_17.svg'),
      url('/img/deco/deco_9.svg');
    background-position:
      top 0 left 0,
      top 2% right 4%;
    background-size:
      18%,
      7%;
    background-repeat: no-repeat;
  }
  .absolute_container {
    width: 100%;
    height: 100%;
    position: relative;
    .a_sanzaingen {
      position: absolute;
      top: 35%;
      width: 34%;
      transform: translate(-30%, -40%);
      .a_wrap {
        width: 100%;
        position: relative;
        .gif {
          width: 82%;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(25%, -43%);
        }
      }
    }
    .a_sensaichijiku {
      position: absolute;
      top: 55%;
      left: 50%;
      width: 7%;
      max-width: 120px;
      transform: translateX(calc(-50% - min(890px, calc(100vw - 20px * 2)) / 2));
      .a_wrap {
        width: 100%;
        position: relative;
        transform: translateX(-50%);
        .kana {
          transform: rotate(90deg);
        }
        .gif {
          width: 300%;
          position: absolute;
          bottom:  100%;
          right: 0;
          transform: translate(50%, 40%);
        }
      }
    }
    .a_kosupapurika {
      position: absolute;
      top: 68%;
      left: 0;
      width: 20%;
      .a_wrap {
        width: 100%;
        position: relative;
        transform: translateX(-20%);
        .kana {}
        .gif {
          width: 120%;
          position: absolute;
          bottom:  87%;
          left: 50%;
          transform: translateX(-45%);
        }
      }
    }
    .a_papurikapurple {
      position: absolute;
      bottom: 0;
      right: 55%;
      width: 26%;
      transform: translateY(20%);
      .a_wrap {
        width: 100%;
        position: relative;
        .kana {
          transform: rotate(-90deg);
        }
        .gif {
          width: 80%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: rotate(24deg) translate(-30%, -75%);
        }
      }
    }
    .a_papurikapink {
      position: absolute;
      bottom: 5%;
      right: 5%;
      width: 10%;
      .a_wrap {
        width: 100%;
        position: relative;
        .kana {
        }
        .gif {
          width: 220%;
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translate(-50%, 15%);
        }
      }
    }
    .a_chekeradish {
      position: absolute;
      top: 60%;
      left: 50%;
      width: 25%;
      transform: translateX(calc(min(890px, calc(100vw - 40px)) / 2));
      .a_wrap {
        width: 100%;
        position: relative;
        transform: translate(-20%, -20%);
        .kana {
          position: relative;
        }
        .gif {
          width: 80%;
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translate(-50%, 10%);
        }
      }
    }
    .a_ganbarukkora {
      position: absolute;
      top: 18%;
      right: 0;
      width: 30%;
      .a_wrap {
        width: 100%;
        position: relative;
        transform: translateX(20%);
        .kana {
          transform: rotate(-135deg);
        }
        .gif {
          width: 140%;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-45%, -10%);
        }
      }
    }
  }
}
.content {
  width: 90%;
  max-width: 1320px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.italic {
  font-style: italic;
}
.choikuse_title {
  width: 80%;
  margin: 0 auto;
}

.text_wave {
  &_wrap {
    padding-top: 30px;
  }
  position: relative;
  width: fit-content;
  font-weight: 700;
  line-height: 1;
  font-size: 38px;
  background-color: white;
  border-bottom:  1px wavy var(--primary-black);
  margin: 0 auto;
  &:not(:first-child) {
    margin-top: 16px;
  }
  span {
    &.half {
      letter-spacing: -5px;
    }
    &.narrow {
      letter-spacing: -1px;
    }
    &.space_right {
      letter-spacing: 5px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    background-image: url('/img/effects/wave_1.png');
    background-size: auto 15px;
    width: 100%;
    height: 15px;
  }
}

.button_wrapper {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
}

.start {
  &_wrap {
    position: relative;
    padding: 0 16px;
    margin-top: 10%;
  }
  &_content {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -5%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  &_character {
    position: absolute;
    bottom: 50%;
    width: 35%;
    > img {
      transform: translateY(20%);
    }
  }
  &_btn {
    position: absolute;
    top: calc(45%);
  }
}

.toge {
  padding-top: percentage(121 / 291);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  &.show {
    opacity: 1;
  }
  transform-origin: center;

  background-image: url('/img/effects/togetoge_c.svg');
  animation: shake 2s infinite, flash 1s infinite;
}

.cloud_box {
  z-index: 1;
  position: relative;
  width: calc(100% - 20px * 2);
  max-width: 890px;
  margin: -12% auto 0;
  padding: 75px min(160px, 22%) min(18%, 150px);
  border-radius: 10px;
  background-image: url('/img/effects/cloud_bg.png');
  background-size: 96% 98%;
  background-position: center;
  background-repeat: no-repeat;
  // animation: mokumoku 3s infinite;
  &_title {
    font-size: 54px;
    font-weight: bold;
    margin-bottom: 30px;
    line-height: 1.2;
  }
  &_subtitle {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 35px;
  }
}

.statistics {
  font-weight: 700;
  > li {
    font-size: 25px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
  }
  &_img {
    width: 100px;
    height: 100px;
    img {
      object-fit: contain;
    }
    &.enf { 
      img { transform: scale(1.2); }
    }
    &.esf { 
      img { transform: scale(1.1); }
    }
    &.est { 
      img { transform: scale(1.4); }
    }
    &.inf { 
      img { transform: scale(.9); }
    }
    &.isf { 
      img { transform: scale(.8); }
    }
    &.int { 
      img { transform: scale(1) translateX(-8%);}
    }
    &.ist { 
      img { transform: scale(.75); }
    }
  }

  &_secret {
    width: 7em;
    border-radius: 50px;
    border: 2px dashed var(--primary-black);
  }

  &_ratio {
    font-size: 30px;
  }
  &_arrow {
    position: relative;
    height: 7px;
    flex: 1;
    border: 1px solid #000;
    border-radius: 7px;
    margin: 0 12px;

    &::after, &::before {
      position: absolute;
      content: '';
      width: 7px;
      height: 21px;
      border-radius: 7px;
      background-color: #000;
      border: 1px solid #000;
      right: -2px;
      transform-origin: 3.5px 3.5px;
    }
    &::before {
      transform: rotate(135deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
}

.bubble {
  &_container {
    position: absolute;
    // bottom: -3%;
    top: 100%;
    right: 30%;
    width: 36vw;
    transform: translate(55%, -30%);
  }
  &_wrap {
    width: 100%;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: -0.2px;
    position: relative;
    > * {
      position: relative;
      z-index: 2;
    }
    .kiwi {
      z-index: 1;
      height: initial !important;
      position: absolute;
      width: 50% !important;
      bottom: 35%;
      right: 0;
    }
  }

  z-index: 2;
  position: relative;
}

@include mdView {
  .wrap {
    padding-bottom: 200px;
  }
  .absolute_wrap {
    .absolute_container {
      .a_sanzaingen {
        transform: translate(-30%, -30%);
      }
      .a_sensaichijiku {
        transform: translateX(calc(-50% - min(700px, 100vw - 40px) / 2));
        .a_wrap {}
      }
      .a_chekeradish {
        transform: translateX(calc(min(700px, calc(100vw - 40px)) / 2));
        .a_wrap {
        }
      }
    }
  }
  .text_wave {
    font-size: 28px;
    &::after {
      background-size: auto 12px;
      height: 12px;
    }
    &:not(:first-child) {
      margin-top: 13px;
    }
  }
  .start {
    &_wrap {
      margin-top: 30px;
    }
    &_character {
      width: 25%;
    }
  }
  .toge {
    margin: 0 auto;
    opacity: 0;
  }

  .cloud_box {
    padding: 75px 130px max(120px, 15%);
    width: auto;
    max-width: 700px;
    &_title {
      font-size: 36px;
      margin-bottom: 7.5px;
    }
    &_subtitle {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
  .statistics {
    > li {
      font-size: var(--font-defalut-md);
    }
    &_img {
      width: 75px;
      height: 75px;
    }
    &_ratio {
      font-size: 24px;
    }
  }
}
@include smView {
  .wrap {
    padding-bottom: 160px;
    overflow: hidden;
  }
  .absolute_wrap {
    &.back {
      background-image:
        url('/img/deco/deco_19.svg'),
        url('/img/deco/deco_9.svg'),
        url('/img/deco/deco_20.svg'),
        url('/img/deco/deco_18.svg'),
        url('/img/deco/deco_21.svg');
      background-position:
        top 0 left 0,
        top 2% right 4%,
        top 35% right 2%,
        bottom 0 right 0,
        top 50% left 2%;
      background-size:
        13%,
        7%,
        9%,
        18%,
        7%;
    }
    .absolute_container {
      .a_sanzaingen {
        top: auto;
        bottom: 60px;
        left: 50%;
        width: 35%;
        transform: translate(-50%, 50%);
        .a_wrap {
          .gif {
            width: 100%;
            left: 40%;
            top: 0%;
            transform: translate(-50%, -55%);
          }
        }
      }
      .a_sensaichijiku {
        transform: translate(-30%, 10%);
        top: 25%;
        left: 0;
        width: 25%;
        .a_wrap {
          transform: translate(0,0);
          .kana {
            transform: rotate(0);
          }
          .gif {
            width: 140%;
            transform: translate(40%, 34%);
          }
        }
      }
      .a_kosupapurika {
        top: 60%;
        left: 100%;
        transform: translate(-45%, 0);
        .a_wrap {
          .kana {
            transform-origin: center;
            transform: rotate(90deg);
          }
          .gif {
            width: 200%;
            bottom: 100%;
            left: 0;
            transform: translate(-30%, 15%);
          }
        }
      }
      .a_yo {
        position: absolute;
        top: 60%;
        left: 0;
        width: 20%;
        transform: translateX(-70%);
      }
      .a_ganbarukkora {
        top: 10%;
        width: 30%;
      }
      .a_chekeradish {
        bottom: 0;
        top: auto;
        left: 10%;
        right: auto;
        transform: translate(0, 60%);
        width: 20%;
        .a_wrap {
          .kana {
            transform: rotate(90deg);
          }
          .gif {
            width: 130%;
            transform: translate(-30%, 50%);
          }
        }
      }
    }
  }
  .text_wave {
    font-size: 16px;
    &_wrap {
      padding-top: 14px;
    }
    &:not(:first-child) {
      margin-top: 9px;
    }
    &::after {
      background-size: auto 8px;
      height: 8px;
    }
  }
  .start {
    &_wrap {
      margin-top: 30px;
    }
    &_character {
      width: 30%;
    }
  }
  .toge {
    margin: 0 auto;
    opacity: 0;
  }

  .cloud_box {
    padding: 50px min(15%, 80px) 17%;
    &_title {
      font-size: 21px;
      margin-bottom: 7.5px;
    }
    &_subtitle {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
  .statistics {
    > li {
      font-size: var(--font-defalut-sm);
    }
    &_img {
      width: 48px;
      height: 48px;
    }
    &_ratio {
      font-size: 18px;
    }
    &_arrow {
      height: 4px;
      border: 1px solid #000;
      border-radius: 6px;
      margin: 0 9px;

      &::after, &::before {
        width: 4px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #000;
        right: -2px;
        transform-origin: 2px 2px;
      }
      &::before {
        transform: rotate(135deg);
      }
      &::after {
        transform: rotate(45deg);
      }
    }
  }
  .bubble {
    &_container {
      top: 100%;
      right: 30%;
      width: 70vw;
      transform: translate(30%, -35%);
    }
    &_wrap {
      .kiwi {
        width: 43% !important;
        bottom: 21%;
        right: -15%;
      }
    }
  }
}
@include xsView {
  .wrap {
    padding-bottom: 140px;
  }
  .text_wave {
    font-size: 12px;
    &_wrap {
      padding-top: 12px;
    }
    &:not(:first-child) {
      margin-top: 3.5px;
    }
    &::after {
      background-size: auto 3px;
      height: 3px;
    }
  }
  .start {
    &_wrap {
      z-index: 2;
    }
    &_btn {
      top: 40%;
    }
  }
  .cloud_box {
    padding: 50px min(20%, 50px) 17%;
  }
  .statistics {
    > li {
      font-size: 12px;
    }
  }
}

.pc {
  @include smView {
    display: none;
  }
}
.sp {
  display: none;
  @include smView {
    display: block;
  }
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  5% { transform: rotate(-2deg); }
  10% { transform: rotate(2deg); }
  15% { transform: rotate(-2deg); }
  20% { transform: rotate(2deg); }
  25% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); } /* 0.5秒停止する部分 */
}
@keyframes flash {
  0% { background-image: url('/img/effects/togetoge_a.svg');}
  32% { background-image: url('/img/effects/togetoge_a.svg');}
  33% { background-image: url('/img/effects/togetoge_c.svg');}
  65% { background-image: url('/img/effects/togetoge_c.svg');}
  66% { background-image: url('/img/effects/togetoge_b.svg');}
  99% { background-image: url('/img/effects/togetoge_b.svg');}
  100% { background-image: url('/img/effects/togetoge_a.svg');}
}
</style>
