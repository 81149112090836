<template>
  <div :class="s.wrap">
    <div :class="s.deco_container"/>
    <div :class="s.content">
      <!-- title -->
      <div :class="s.title">
        <div :class="s.main"><img src="/img/pages/whatchoiku/whatchoiku.svg" alt=""></div>
        <div :class="s.subtitle"><img src="/img/pages/whatchoiku/whatschoikuproject.svg" alt=""></div>
      </div>

      <div :class="s.cards">
        <!-- about -->
        <div>
          <div :class="s.about">
            <div :class="s.logo">
              <img src="/img/pages/whatchoiku/choiku_about.png" alt="">
              <!-- <img :class="s.gif" src="/img/pages/whatchoiku/choiku_about.gif" alt=""> -->
            </div>
            <div :class="s.text">若い世代に向けて、<br>「ちょいくせのある野菜たち」と、<br>楽しく“ちょいく”を伝えていく。<br>そんなプロジェクトです！</div>
            <!-- accordion -->
            <div :class="[s.about_accordion_wrap, s.sp]">
              <div
                :class="s.trigger_wrap"
                @click="triggerClicked">
                <div :class="[s.trigger_img, flag.triggerClicked ? s.isOn : '']">
                  <img src="/img/effects/trigger_main.svg" alt="">
                </div>
                <div :class="s.trigger_giza">
                  <img src="/img/pages/whatchoiku/accordion_giza.svg" alt="">
                </div>
              </div>
              <div
                id="accordion-content-sp"
                :class="[s.about_accordion, flag.showAbout ? s.show : '']">
                <div :style="`max-height: ${accordionMaxH}px;`">
                  <div :class="s.about_accordion_logo">
                    <img src="/img/logos/choiku.png" alt="">
                  </div>
                  <div :class="s.about_accordion_text">
                    <div :class="s.about_accordion_title">お金は<br class="sp">育てられる。</div>
                    <div :class="s.about_accordion_detail">
                      <div>世の中の関心が、お金に向いています。これまで以上に。</div>
                      <div>ですが、記憶に残るのは、暗い話題ばかり。<br>「投資」や「資産形成」に対しても、<br>まだまだ、ネガティブな印象を持つ人がいると聞きます。</div>
                      <div>大人でさえ、そうであるなら、<br>これからの社会を支えていく若い世代は、<br>余計に感じていることでしょう。</div>
                      <div>だから、まずはポジティブな印象を持ってほしい。<br>お金とのつき合い方に、ほんの少しでも楽しさを感じてほしい。<br>そのために語るべきは、前向きな真実。<br>「お金は育てられる。」ということだと思うのです。</div>
                      <div>このプロジェクト名とロゴには、<br>私たちのそんな想いを込めました。</div>
                      <div>「貯めて、蓄える」から、<br>「貯めて、育てる」時代へ。<br>そのきっかけになりたい、ちょいくプロジェクトです。</div>
                      <div :class="s.note">ちょいくプロジェクトは「<a href="https://www.toushin.or.jp/" target="_blank">一般社団法人投資信託協会</a>」による、<br>高校生の「お金とのつき合い方」を啓蒙するプロジェクトです。</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- characters -->
        <div :class="s.characters_wrap">
          <div :class="s.characters" id="characters-scroll-container">
            <div :class="s.character_container" id="characters-scroll-content">
              <div
                :class="[
                  s.character_wrap,
                  s[c.name],
                  i === showCharacterIdx ? s.show : s.hide,
                ]"
                v-for="(c, i) in characters"
                :key="i">
                <div :class="s.character_bg">
                  <img src="/img/characters/card/card_bg.png" alt="">
                </div>
                <div :class="s.character_content_wrap">
                  <div :class="s.character_content">
                    <div :class="s.character_logo">
                      <img
                        :class="s.img"
                        :src="`/img/characters/card/logo_${c.name}.svg`" alt="">
                    </div>
                    <div :class="s.character_img">
                      <!-- <img
                        :class="s.img"
                        :src="`/img/characters/card/card_${c.name}.png`" alt=""> -->
                      <img
                        :class="[s.img, s.gif]"
                        :src="`/img/characters/card/card_${c.name}.gif`" alt="">
                    </div>
                    <div :class="s.character_text_wrap">
                      <div :class="s.character_text">{{ c.label }}</div>
                      <div :class="s.character_description" v-html="c.description"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="s.arrows">
            <div
              v-show="showCharacterNum !== 1"
              @click="changeShowCharacter('back')"/>
            <div
              v-show="showCharacterNum !== characters.length"
              @click="changeShowCharacter('next')"/>
          </div>
        </div>
      </div>

      <div :class="[s.about_accordion_wrap, s.pc]">
        <div
          :class="s.trigger_wrap"
          @click="triggerClicked">
          <div :class="s.trigger_img">
            <img src="/img/effects/trigger_main.svg" alt="">
          </div>
          <div :class="s.trigger_giza">
            <img src="/img/pages/whatchoiku/giza_pc.svg" alt="">
          </div>
        </div>
        <div
          id="accordion-content-pc"
          :class="[s.about_accordion, flag.showAbout ? s.show : '']">
          <div :style="`max-height: ${accordionMaxH}px;`">
            <div :class="s.about_accordion_logo">
              <img src="/img/logos/choiku.png" alt="">
            </div>
            <div :class="s.about_accordion_text">
              <div :class="s.about_accordion_title">お金は<br class="sp">育てられる。</div>
              <div :class="s.about_accordion_detail">
                <div>世の中の関心が、お金に向いています。これまで以上に。</div>
                <div>ですが、記憶に残るのは、暗い話題ばかり。<br>「投資」や「資産形成」に対しても、<br>まだまだ、ネガティブな印象を持つ人がいると聞きます。</div>
                <div>大人でさえ、そうであるなら、<br>これからの社会を支えていく若い世代は、<br>余計に感じていることでしょう。</div>
                <div>だから、まずはポジティブな印象を持ってほしい。<br>お金とのつき合い方に、ほんの少しでも楽しさを感じてほしい。<br>そのために語るべきは、前向きな真実。<br>「お金は育てられる。」ということだと思うのです。</div>
                <div>このプロジェクト名とロゴには、<br>私たちのそんな想いを込めました。</div>
                <div>「貯めて、蓄える」から、<br>「貯めて、育てる」時代へ。<br>そのきっかけになりたい、ちょいくプロジェクトです。</div>
                <div :class="s.note">ちょいくプロジェクトは「<a href="https://www.toushin.or.jp/" target="_blank">一般社団法人投資信託協会</a>」による、<br>高校生の「お金とのつき合い方」を啓蒙するプロジェクトです。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhatChoiku',
  props: {},
  data() {
    return {
      showCharacterNum: 1, // charactersの何番目の要素か
      flag: {
        showAbout: false,
        triggerClicked: false,
      },
      characters: [
        {
          name: 'seebon',
          label: 'シーボン',
          description: 'ある日突然発見された、謎のモコモコ生物。<br>増えたり頭から発芽したり、<br>やりたい放題。学者曰く、<br>「シーボンには進化系が存在する」<br>のだとか。現在その生態を調査中…。',
        },
        {
          name: 'sanzaingen',
          label: 'さんざインゲン',
          description: '推し活大好きJK。推しのためなら<br>お金や努力を惜しまない。<br>興奮すると頭からマメやコインが飛び出る。<br>超天然マイペースだけどトレンド<br>には敏感。せんさイチジクと仲良し。',
        },
        {
          name: 'ganbarucola',
          label: 'がんばルッコラ',
          description: '助けを求める声に敏感なヒーロー。<br>頼みごとは断らない（断れない）。だから、<br>いつも損したり、時には騙されることも。<br>毎日大変だけど、エナドリ飲んで<br>今日も健気にがんばる！',
        },
        {
          name: 'cospapurika',
          label: 'コスパプリカ',
          description: '自撮りと映えが大好きな女の子。<br>華やかな暮らしぶりで、みんなにとって<br>憧れの存在。実際はコスパ命で、<br>家では干物女。三姉妹の長女で<br>妹にタイパプリカとマネパプリカがいる。',
        },
        {
          name: 'sensaichijiku',
          label: 'せんさイチジク',
          description: '鋭い感性で些細な変化に気づいたり、<br>天候や気圧の変化を当てられるのが特技。<br>人の多い場所が苦手で基本的に一人が<br>好きだけど、さんざインゲンは特別。<br>ちょっぴり依存気味？',
        },
        {
          name: 'kenkiwi',
          label: 'けんキウイ',
          description: '好きなことに没頭する天才。<br>頭の回転が速過ぎて、相手の気持ちに<br>寄り添うのが苦手。メガネを取ると、<br>じつはイケメンという噂も。口癖は<br>「これってあなたの発想ですよね？」',
        },
        {
          name: 'chekeradish',
          label: 'チェケラディッシュ',
          description: 'フットワークが軽過ぎてどこにでも現れる。<br>だれとでも仲良くなれる。ヒップホップや<br>スケボー、ストリートカルチャーを<br>こよなく愛するけど、海だけは苦手。<br>葉がしおれるから。',
        },
        {
          name: 'secret',
          label: 'シークレット',
          description: '神出鬼没な謎の存在。そのハイスペックさと<br>品の良さから、名家の生まれだとか、<br>前世は貴族だとか、さまざまな<br>憶測が飛び交う。本気を出すと<br>目が開くという噂も、真偽は不明。',
        },
      ],
      accordionMaxH: 0,
      scrollTimer: null,
    };
  },
  created() {},
  mounted() {
    const scrollContainer = document.getElementById('characters-scroll-container');
    scrollContainer.addEventListener('scroll', () => {
      if (this.scrollTimer !== false) clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        this.scrollCharacter();
      }, 200);
    });
  },
  methods: {
    scrollCharacter(targetNum) {
      const scrollContainer = document.getElementById('characters-scroll-container');
      const scrollContent = document.getElementById('characters-scroll-content');
      const containerPosX = scrollContainer.getBoundingClientRect().x;
      const contentPosX = scrollContent.getBoundingClientRect().x;
      const cardW = scrollContent.clientWidth;
      const scrolledX = (containerPosX + 1) - contentPosX;
      this.showCharacterNum = targetNum || Math.round(scrolledX / cardW) + 1;
      const targetX = (this.showCharacterNum - 1) * cardW - scrolledX;
      scrollContainer.scrollBy({
        left: targetX,
        behavior: 'smooth',
      });
    },
    changeShowCharacter(type) {
      if (type === 'next' && this.showCharacterNum !== this.characters.length) this.showCharacterNum += 1;
      if (type === 'back' && this.showCharacterNum !== 1) this.showCharacterNum -= 1;
      this.scrollCharacter(this.showCharacterNum);
    },
    triggerClicked() {
      this.flag.triggerClicked = true;
      this.flag.showAbout = !this.flag.showAbout;
      if (this.flag.showAbout) {
        const winW = window.innerWidth;
        const winH = window.innerHeight;
        const type = winW < 768 ? 'sp' : 'pc';
        const accordionElem = document.getElementById(`accordion-content-${type}`);
        const elemPos = accordionElem?.getClientRects()[0].top;
        if (!accordionElem || !winH) return;
        const bottomLen = winH - elemPos;
        this.accordionMaxH = bottomLen - 20;
      }
      setTimeout(() => {
        this.flag.triggerClicked = false;
      }, 100);
    },
  },
  computed: {},
};
</script>

<style lang="scss" module="s">
.wrap {
  border-top: 2px solid var(--primary-black);
  text-align: center;
  background-color: white;
  font-weight: 700;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-image:
    linear-gradient(to right, rgba(0, 0, 0, 0.5) 0.5px, transparent 0.5px),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0.5px, transparent 0.5px);
  background-size: 50px 50px; /* 格子のサイズを調整 */
  position: relative;
}
.deco_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image:
    url('/img/deco/deco_4.svg'),
    url('/img/deco/deco_9.svg'),
    url('/img/deco/deco_16.svg'),
    url('/img/deco/deco_6.svg'),
    url('/img/deco/deco_15.svg'),
    url('/img/deco/deco_8.svg'),
    url('/img/deco/deco_14.svg');
  background-size:
    13%,
    6%,
    3%,
    10%,
    5%,
    2%,
    3%;
  background-position:
    top left,
    top 3% right 3%,
    top 20% left 8%,
    top 60% left 0,
    top 68% left 2%,
    top 68% right 2%,
    top 30% right 3%;
}
.content {
  padding: 131px 0 46px;
}
.title {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 4%;
  z-index: 2;
  .main {
    margin: 0 auto;
    width: 43%;
    position: relative;
    z-index: 2;
  }
  .subtitle {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -40%);
    width: 65.5%;
    max-width: 1200px;
  }
}

.cards {
  display: flex;
  max-width: 1140px;
  height: fit-content;
  margin: 0 auto;
  justify-content: center;
  align-items: stretch;
  padding: 0 48px;
  > * {
    width: 50%;
  }
}

.about {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border: 2px solid var(--primary-black);
  padding-top: 27%;
  padding-bottom: 15px;
  border-radius: 500px 500px 53px 53px;
  background-color: var(--primary-white);
  position: relative;
  .logo {
    width: 60%;
    margin: 0 auto;
    .gif {
      position: relative;
      left: -17%;
      width: 134%;
      height: 74%;
      object-fit: cover;
      margin: 0 auto;
    }
  }
  .text {
    margin-top: 67px;
    font-size: 16px;
  }
}

.content_wrap {
  position: relative;
  width: 100%;
  height: fit-content;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.about_accordion_wrap {
  position: relative;
  z-index: 4;
}

.trigger_wrap {
  margin-top: 55px;
  position: relative;
  .trigger_img {
    position: relative;
    width: 25vw;
    min-width: 100px;
    z-index: 3;
    margin: 0 auto;
    > img {
      transition: all .3s;
      position: relative;
      z-index: 2;
      transform: translate(-0.4vw, -0.4vw);
    }
    &::before {
      position: absolute;
      width: 25vw;
      min-width: 100px;
      z-index: 1;
      content: '';
      top: 50%;
      left: 50%;
      height: calc(100%);
      transform: translate(calc(-50% + .4vw), calc(-50% + .4vw));
      background-image: url('/img/effects/trigger_bg.svg');
      transition: all .3s;
      background-repeat: no-repeat;
    }
    &:hover {
      > img {
        transform: translate(.4vw, .4vw);
      }
    }
  }
  .trigger_giza {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    z-index: 2;
  }
}

.about_accordion {
  position: absolute;
  top: 30%;
  left: 50%;
  background-color: var(--primary-white);
  width: 50%;
  margin: 0 auto;
  font-weight: 600;
  transform-origin: top;
  transform: translateX(-50%);
  max-height: 0;
  text-align: left;
  z-index: 1;
  > * {
    height: 120vw;
    overflow: scroll;
    width: calc(100% + 2px);
    padding: 100px 40px 176px;
    background-color: var(--primary-white);
    border: 1px solid var(--primary-black);
    border-top: none;
    transition: all .3s;
    transform-origin: top;
    transform: scaleY(0) translateY(-.3vw);
    border-radius: 0 0 15px 15px;
  }

  &.show {
    > * {
      transform: scaleY(100%) translateY(-.3vw);
    }
  }

  &_logo {
    width: 80%;
    margin: 0 auto 87px;
  }
  &_text {
    width: 80%;
    margin: 0 auto;
  }
  &_title {
    font-size: 41px;
    font-weight: 700;
    margin-bottom: 32px;
    line-height: 1.3;
  }
  &_detail {
    font-size: var(--font-default);
    font-weight: 700;
    > * {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }
    a {
      text-decoration: underline;
    }
    .note {
      color: var(--primary-gray);
      font-weight: 600;
      font-size: 14px;
      a {
        color: var(--primary-gray);
      }
    }
  }
}


.characters {
  &_wrap {
    position: relative;
  }
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  border-radius: 500px 500px 53px 53px;
  background-color: var(--primary-white);
  border: 1px solid var(--primary-black);
}

.character {
  &_container {
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    > * {
      flex-shrink: 0;
    }
  }
  &_standard {
    width: 100%;
    padding-top: calc(100% * 434 / 285);
  }
  &_wrap {
    height: 100%;
    width: 100%;
    position: relative;
    // &.show {
    //   left: 0;
    // }
    // &.hide {
    //   left: 100%;
    // }
    // キャラクターごとに横幅変更
    $characters: 'seebon','sanzaingen','ganbarucola','cospapurika','sensaichijiku','kenkiwi','chekeradish','secret';
    $c-logo-w: (
      seebon: 53%,
      sanzaingen: 45%,
      ganbarucola: 54%,
      cospapurika: 65%,
      sensaichijiku: 57%,
      kenkiwi: 64%,
      chekeradish: 49%,
      secret: 42%,
    );
    $c-img-w: (
      seebon: 79%,
      sanzaingen: 106%,
      ganbarucola: 85%,
      cospapurika: 75%,
      sensaichijiku: 57%,
      kenkiwi: 65%,
      chekeradish: 70%,
      secret: 63%,
    );
    $c-transform-x: (
      seebon: 0%,
      sanzaingen: 2%,
      ganbarucola: 0%,
      cospapurika: 3%,
      sensaichijiku: 0%,
      kenkiwi: -2%,
      chekeradish: 5%,
      secret: 1%,
    );
    $c-transform-y: (
      seebon: -4%,
      sanzaingen: -9%,
      ganbarucola: 0%,
      cospapurika: -7%,
      sensaichijiku: 0%,
      kenkiwi: -9%,
      chekeradish: -5%,
      secret: -2%,
    );
    @for $i from 1 through length($characters) {
      &.#{nth($characters, $i)} {
        $c-name: nth($characters, $i);
        $transform-x: map-get($c-transform-x, $c-name);
        $transform-y: map-get($c-transform-y, $c-name);
        $img-w: map-get($c-img-w, $c-name);
        $logo-w: map-get($c-logo-w, $c-name);
        .character_img {
          width: 100%;
          padding-top: percentage(71 / 87);
          position: relative;
          overflow: visible;
          > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
            object-fit: contain;
            &.gif {
              position: absolute;
              overflow: visible;
              height: initial;
              top: 50%;
              left: 50%;
              width: $img-w;
              transform: translate(calc(-50% + $transform-x), calc(-50% + $transform-y));
              object-fit: cover;
            }
          }
        }
        .character_logo {
          width: $logo-w;
        }
      }
    }
  }
  &_bg {
    height: fit-content;
  }
  &_content_wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 30%;
    top: 0;
    left: 0;
  }
  &_content {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_logo {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &_text_wrap {
    margin: -33px auto 0;
    position: relative;
    flex: 1;
  }
  &_text {
    position: absolute;
    left: 50%;
    top: 0;
    padding: 12px 28px;
    border: 2px solid var(--primary-black);
    border-radius: 50px;
    width: fit-content;
    transform: rotate(-5deg) translate(-50%, -50%);
    background-color: var(--primary-pink);
    font-size: 29px;
    white-space: nowrap;
  }
  &_description {
    padding-top: 70px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: var(--font-default);
  }
}

.arrows {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  > * {
    content: '';
    position: absolute;
    width: 12.8%;
    padding-top: 12.8%;
    background-image: url('/img/pages/whatchoiku/arrow.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    pointer-events: auto;
    &:nth-child(1) {
      left: 0;
      transform: translateX(-50%);
    }
    &:nth-child(2) {
      right: 0;
      transform: translateX(50%) rotate(180deg);
    }
  }
}

@include mdView {
  .wrap {
    background-size: 30px 30px; /* 格子のサイズを調整 */
  }
  .about {
    .text {
      margin-top: 28px;
    }
  }
  .about_accordion {
    &_logo {
      width: 95%;
    }
    &_text {
      width: 95%;
    }
    &_title {
      font-size: 32px;
    }
    &_detail {
      font-size: var(--font-default-md);
      .note {
        font-size: 12px;
      }
    }
  }
  .character {
    &_text {
      padding: 8px 16px;
      font-size: 14px;
      border-width: 1px;
    }
    &_description {
      padding-top: 25px;
      font-size: var(--font-default-sm);
    }
  }
}

@include smView {
  .wrap {
    background-size: 20px 20px; /* 格子のサイズを調整 */
  }
  .deco_container {
    background-image:
      url('/img/deco/deco_5.svg'),
      url('/img/deco/deco_6.svg'),
      url('/img/deco/deco_8.svg'),
      url('/img/deco/deco_9.svg'),
      url('/img/deco/deco_10.svg'),
      url('/img/deco/deco_11.svg'),
      url('/img/deco/deco_12.svg'),
      url('/img/deco/deco_13.svg');
    background-size:
      10%,
      8%,
      3%,
      6%,
      13%,
      30%,
      5%,
      7%;
    background-position:
      top left,
      top 12% left 0,
      top 20% right 10px,
      top 20px right 20px,
      top 58% right 5%,
      top 57% left -30px,
      top 75% left 20px,
      bottom 5% left 0;
  }
  .content {
    padding: 35px 0 46px;
  }
  .title {
    display: block;
    padding-bottom: 0;
    .main {
      width: 75%;
    }
    .subtitle {
      width: 85%;
      position: relative;
      transform: translate(-50%, 0);
    }
  }
  .cards {
    display: block;
    padding: 0 32px;
    overflow: hidden;
    > * {
      width: 100%;
      &:not(:first-child) {
        margin-top: 60px;
      }
    }
  }
  .about {
    padding-top: 20%;
    border-radius: 500px 500px 53px 53px;
    .text {
      margin-top: 28px;
    }
  }
  .accordion_wrap {
    width: 81.25%;
  }

  .about_accordion {
    width: 78%;
    > * {
      width: calc(100% + 2px);
      padding: 80px 20px 60px;
    }
    &.show {
      > * {
        transform: scaleY(100%) translate(0, 1vw);
      }
    }

    &_logo {
      margin: 0 auto 60px;
    }
    &_title {
      font-size: 32px;
      margin-bottom: 15px;
    }
    &_detail {
      font-size: var(--font-default-sm);
      > * {
        &:not(:first-child) {
          margin-top: 15px;
        }
      }
      .note {
        font-size: 10px;
      }
    }
  }

  .about_accordion_wrap {
    position: relative;
    z-index: 4;
  }

  .trigger_wrap {
    margin-top: 20px;
    position: relative;
    .trigger_img {
      position: relative;
      width: 25vw;
      z-index: 3;
      margin: 0 auto;
      transition: all .1s;
      &:hover {
        > img {
          transform: translate(-0.4vw, -0.4vw);
        }
      &.isOn {
        > img {
          transform: translate(0, 0);
        }
      }
      }
    }
    .trigger_giza {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 85%;
      z-index: 2;
    }
  }
  .character {
    &_text {
      border-width: 1px;
    }
    &_description {
      padding-top: 20px;
      font-size: var(--font-default-sm);
    }
  }
}

@include xsView {
  .wrap {
    background-size: 15px 15px; /* 格子のサイズを調整 */
  }
  .cards {
    > * {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
  .character {
    &_text {
      padding: 5px 10px;
      font-size: 11px;
    }
    &_description {
      font-size: var(--font-default-xs);
    }
  }
  .about_accordion {
    > * {
      padding: 60px 20px 40px;
    }
    &_logo {
      margin: 0 auto 30px;
    }
    &_title {
      font-size: 24px;
    }
    &_detail {
      font-size: var(--font-default-xs);
      .note {
        font-size: 10px;
      }
    }
  }
}

.pc {
  @include smView {
    display: none;
  }
}
.sp {
  display: none;
  @include smView {
    display: block;
  }
}
</style>
