<template>
  <div :class="s.wrap">
    <!--カーソル-->
    <p
      :class="s.cursor"
      :style="{ opacity: opacityA, top: mouseY + 'px', left: mouseX + 'px' }"></p>
    <!--マウスストーカー-->
    <div
      v-if="!hideStalker"
      :class="[
        s.stalker,
        flag.showStalker ? s.show : '',
      ]"
      v-bind:style="{ transform: 'translate('+posX+'px,'+posY+'px)' }">
      <img src="/img/characters/png/shibon.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: {
        showStalker: false,
      },
      mouseX: 0,
      mouseY: 0,
      posX: 0,
      posY: 0,
      opacityA: 0,
      opacityB: 0,
    };
  },
  watch: {
    $route() {
      this.flag.hideStalker = false;
      this.mouseX = 0;
      this.mouseY = 0;
      this.posX = 0;
      this.posY = 0;
      this.opacityA = 0;
      this.opacityB = 0;
      setTimeout(() => {
        this.flag.hideStalker = true;
      }, 200);
    },
  },
  methods: {
    getCursorCoordinate(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      if (this.opacityA === 0) {
        this.opacityA = 1;
      }
      setTimeout(() => {
        this.posX = `${this.mouseX - 20}`;
        this.posY = `${this.mouseY - 45}`;
        if (this.opacityB === 0) {
          setTimeout(() => {
            this.flag.showStalker = true;
          });
          this.opacityB = 1;
        }
      }, 200);
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  height: 0;
  z-index: 999;
  position: relative;
  @include smView {
    display: none;
  }
}

.cursor{
  position: fixed; /* 最初はページの左上に配置されるようにする */
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px; /* カーソルの焦点を中央に合わせる */
  z-index: 3; /*一番手前に来るように*/
  background-color: #000;
  border-radius: 50%;
  opacity: 0; /*開いた瞬間非表示*/
  pointer-events: none;
}

.stalker {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  z-index: 2;/*一番手前に来るように*/
  border-radius: 50%;
  opacity: 0; /*開いた瞬間非表示*/
  transform: translate(0,0);
  transition: transform 0.1s;
  transition-timing-function: ease-out;
  pointer-events: none; /*マウス直下に常にstalker要素がくるのでホバー要素が働かなくなる。noneにすることでstalkerを無視する*/
  img {
    width: 100%;
  }
  &.show {
    opacity: 1;
    animation-name: fadein;
    animation-duration: .6s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
