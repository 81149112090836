<template>
  <div :class="s.wrap" @mousemove="callCursorEvent">
    <CursorStalker ref="cursorStalker" />
    <div :class="s.global_header">
      <GlobalHeader />
      <DrawerMenu />
    </div>
    <div class="contents" :class="[s.main, isShowHeader ? s.hasHeader : '']">
      <div :class="s.content">
        <router-view />
      </div>
      <GlobalFooter />
    </div>
    <!-- <TopLoading v-if="$route.path === '/'" /> -->
  </div>
</template>

<script>
// import { mapState, mapActions } from 'vuex';
import {
  GlobalHeader,
  GlobalFooter,
  DrawerMenu,
} from '@/components/layouts';
import {
  CursorStalker,
  // TopLoading,
} from '@/components/parts';

export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter,
    DrawerMenu,
    CursorStalker,
    // TopLoading,
  },
  computed: {
    isShowHeader() {
      const hideHeaderPage = ['Scoring', 'Diagnosis'];
      return !hideHeaderPage.includes(this.$route.name);
    },
  },
  created() {
    // ログインチェック
    this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    // this.$store.dispatch('helper/getMaster');
  },
  watch: {
  },
  methods: {
    callCursorEvent(e) {
      if (this.$refs?.cursorStalker) this.$refs.cursorStalker.getCursorCoordinate(e);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_fonts.scss';
@import '@/assets/scss/animation.scss';
@import '@/assets/scss/transition.scss';
@import '@/assets/scss/style.scss';
</style>

<style lang="scss" module="s">
.wrap {
  // cursor: none;
  overflow: hidden;
}
.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    flex: 1;
    position: relative;
  }

  &.hasHeader {
    min-height: calc(100vh - var(--header-h));
  }
}
</style>
