import { createRouter, createWebHistory } from 'vue-router';
// import Home from '@/views/Home.vue';
/** @Account */
import Login from '@/views/Account/Login.vue';
import Signup from '@/views/Account/Signup.vue';
import SignupPassword from '@/views/Account/SignupPassword.vue';
/** @Pages */
import TopPage from '@/views/Pages/TopPage.vue';
import Diagnosis from '@/views/Pages/Diagnosis.vue';
import Scoring from '@/views/Pages/Scoring.vue';

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    children: [
      {
        path: '/signup/password',
        name: 'signup/password',
        component: SignupPassword,
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/',
    name: 'Top',
    component: TopPage,
  },
  {
    path: '/miraiyohou',
    name: 'Diagnosis',
    component: Diagnosis,
  },
  {
    path: '/result/:type',
    name: 'Scoring',
    component: Scoring,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // ページ遷移時
  scrollBehavior(to, from, savedPosition) {
    let position = {};
    // fromとtoの違いがない場合はスルー
    if (from.path && to.path === from.path) return;
    if (!to.hash) {
      // ハッシュがなく、
      if (savedPosition) { // ブラウザバック
        position = { top: savedPosition.top };
      } else { // ページ遷移
        position = { top: 0 };
      }
    } else {
      // ハッシュが存在する場合スクロール
      position = { selector: to.hash };
    }
    return position;
  },
});

export default router;
