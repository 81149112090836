<template>
  <div :class="s.wrap">
    <div :class="s.bg"/>
    <div :class="s.main">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  
};
</script>

<style lang="scss" module="s">
.wrap {
  position: relative;
  width: fit-content;
  margin-top: 4px;
  margin-left: 10px;
}
.bg {
  position: absolute;
  z-index: -1;
  content: '';
  background-image: url('/img/effects/dot_backimage.png');
  background-size: 200px;
  background-color: yellow;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  border: 4px solid var(--primary-black);
  transition: all .4s;
  animation: flashColors 1.5s infinite;
}
@keyframes flashColors {
  0% { background-color: var(--primary-pink); }
  33% { background-color: var(--primary-pink); }
  34% { background-color: var(--primary-yellow); }
  65% { background-color: var(--primary-yellow); }
  66% { background-color: var(--primary-green); }
  99% { background-color: var(--primary-green); }
  100% { background-color: var(--primary-pink); }
}
.main {
  position: relative;
  padding: 26px 80px;
  display: inline-block;
  font-size: 50px;
  font-weight: 700;
  top: -15px;
  left: -15px;
  background-color: white;
  border-radius: 500px;
  border: 4px solid var(--primary-black);
  transition: all .4s;
  &:hover {
    top: 0;
    left: 0;
  }
}

@include mdView {
  .bg {
    background-size: 150px;
    border-width: 2px;
  }
  .main {
    top: -10px;
    left: -10px;
    border-width: 2px;
    font-size: 28px;
    padding: 18px 36px;
    border-radius: 50px;
  }
}
@include smView {
  .bg {
    border-width: 1px;
    background-size: 100px;
  }
  .main {
    top: -8px;
    left: -8px;
    border-width: 1px;
    font-size: 18px;
    padding: 8px 24px;
  }
}
// @include xsView {
//   .bg {
//     border-width: 1px;
//     background-size: 100px;
//   }
//   .main {
//     top: -4px;
//     left: -5px;
//     border-width: 1px;
//     font-size: 12px;
//     padding: 6px 20px;
//   }
// }
</style>
