<template>
  <div :class="s.wrap">
    <div :class="s.container">
      <!-- bg -->
      <img :class="s.pc" src="/img/kv/kv_bg_pc.png" alt="">
      <img :class="s.sp" src="/img/kv/kv_bg_sp.png" alt="">

      <!-- キャラクター -->
      <div :class="[s.character, s.ichijiku]"><img src="/img/kv/gifs/ichijiku.gif" alt="せんさイチジク"></div>
      <div :class="[s.character, s.kiwi]"><img src="/img/kv/gifs/kiwi.gif" alt="けんキウイ"></div>
      <div :class="[s.character, s.radish]"><img src="/img/kv/gifs/radish.gif" alt="チェケラディッシュ"></div>
      <div :class="[s.character, s.ingen]"><img src="/img/kv/gifs/ingen.gif" alt="さんざインゲン"></div>
      <div :class="[s.character, s.buta, s.sp]"><img src="/img/kv/gifs/buta.gif" alt="ぶた"></div>
      <!-- ルッコラ -->
      <div :class="[s.character, s.rucola]">
        <div :class="s.rucola_container">
          <div :class="s.rucola_gif"><img src="/img/kv/gifs/rucola.gif" alt="がんばルッコラ"></div>
          <img :class="s.rucola_frame" src="/img/kv/rucola_frame_line.svg" alt="がんばルッコラフレームライン">
        </div>
      </div>
      <!-- パプリカ３姉妹 -->
      <div
        :class="[s.character, s.papurika, s[`papurika_${n}`]]"
        v-for="n of 3"
        :key="n"><img :src="`/img/kv/gifs/papurika_${n}.gif`" :alt="`パプリカ${n}`"></div>
      <!-- コイン -->
      <div
        :class="[s.character, s.coin, s[`coin_${n}`]]"
        v-for="n of 3"
        :key="n"><img src="/img/kv/gifs/coin.gif" :alt="`コイン${n}`"></div>
      <!-- うちわ -->
      <div
        :class="[s.character, s.uchiwa, s[`uchiwa_${n}`], s.sp]"
        v-for="n of 3"
        :key="n"><img src="/img/kv/gifs/uchiwa.gif" :alt="`うちわ${n}`"></div>
      <!-- シーボン -->
      <div
        :class="[s.character, s.seebon, s[`seebon_${n}`]]"
        v-for="n of 2"
        :key="n"><img :src="`/img/kv/gifs/seebon_${n}.gif`" :alt="`シーボン${n}`"></div>
      <!-- CHOIKUSE FUTURE -->
      <div :class="[s.character, s.choikuse]"><img src="/img/kv/choikusefuture.svg" alt="ぶた"></div>
    </div>
  </div>
</template>

<style lang="scss" module="s">
.wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  .character {
    position: absolute;
    object-fit: contain;
    transform-origin: center;
  }
}

.choikuse {
  width: 35%;
  top: 15%;
  left: 40%;
}

.rucola {
  width: 8%;
  bottom: 1.3%;
  right: 37.5%;
  &_container {
    width: 100%;
    position: relative;
  }
  &_gif {
    position: absolute;
    z-index: 1;
    width: 100%;
    mask-image: url('/img/kv/rucola_frame_bg.svg');
    mask-repeat: no-repeat;
    > img {
      width: 150% !important;
      transform: translate(-15%, 0);
      background-color: var(--primary-blue);
    }
  }
  &_frame {
    position: relative;
    z-index: 2;
  }
}

.ichijiku {
  width: 18.5%;
  right: 7.5%;
  top: 13%;
}
.ingen {
  width: 18%;
  right: 2%;
  top: -6%;
}
.radish {
  width: 20%;
  left: 8%;
  top: 24%;
  transform: rotate(-10deg);
}
.kiwi {
  width: 20%;
  left: -6.5%;
  top: 18.5%;
}
.coin {
  width: 9%;
  top: -1%;
  &_1 { left: 29%; }
  &_2 { left: 35%; }
  &_3 { left: 41%; }
}
.papurika {
  width: 12%;
  top: -3.5%;
  &_1 { left: 52.5%; }
  &_2 { left: 57%; top: -2.5%; }
  &_3 { left: 65%; }
}
.seebon {
  width: 13%;
  &_1 {
    display: none;
  }
  &_2 {
    left: 42%;
    top: 70.5%;
    transform: rotate(-15deg);
  }
}

@include smView {
  .choikuse {
    width: 40%;
    top: 28%;
    left: 38%;
  }
  
  .ichijiku {
    width: 23%;
    right: -2%;
    top: 26%;
  }
  .ingen {
    width: 23%;
    right: -8%;
    top: 12%;
  }
  .radish {
    width: 24%;
    left: -2%;
    top: 34%;
    transform: rotate(-10deg);
  }
  .kiwi {
    width: 25%;
    left: auto;
    right: 1%;
    top: -15%;
    transform: rotate(90deg);
  }
  .buta {
    width: 17%;
    right: 18.5%;
    top: 82%;
  }
  .coin {
    width: 10%;
    top: 16%;
    &_1 { left: 25%; }
    &_2 { left: 33%; }
    &_3 { left: 41%; }
  }
  .papurika {
    width: 15%;
    top: 14.5%;
    &_1 { left: 53%; }
    &_2 { left: 59%; top: 15.5%; }
    &_3 { left: 69%; }
  }
  .uchiwa {
    width: 19%;
    top: 0%;
    &_1 { left: -8%; }
    &_2 { left: 2%; }
    &_3 { left: 12%; }
  }
  .seebon {
    width: 15%;
    &_1 {
      display: inline-block;
      left: 44%;
      top: -0.3%;
      transform: rotate(15deg);
    }
    &_2 {
      left: 41%;
      top: 66%;
      transform: rotate(-15deg);
    }
  }

  .rucola {
    width: 10%;
    bottom: 15.6%;
    right: 34.5%;
  }
}


.pc {
  @include smView {
    display: none;
  }
}
.sp {
  display: none;
  @include smView {
    display: block;
  }
}
</style>
