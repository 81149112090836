<template>
  <div :class="s.wrap">
    <div v-if="type && flag.isValidtype">
      <!-- 結果のキービジュアル（green背景の部分） -->
      <scoring-key-visual
        :type="type" />
      <!-- 結果の詳細（green背景の部分） -->
      <div :class="s.detail">
        <div :class="s.detail_container">
          <div :class="[s.content, s.personality]">
            <div :class="s.content_label">性格タイプ</div>
            <div :class="s.content_title">{{ detail.personality?.title }}</div>
            <div :class="s.content_detail" v-html="detail.personality?.detail" />
            <div :class="s.content_item">
              <img
                :class="[s.uchiwa, s.item]"
                src="/img/items/uchiwa.png" alt="">
              <img
                :class="[s.pc, s.takei, s.item]"
                src="/img/items/takei.svg" alt="">
            </div>
          </div>
          <div :class="[s.content, s.forecast]">
            <div :class="s.content_label">未来予報</div>
            <div :class="s.content_title">{{ detail.forecast?.title }}</div>
            <div :class="s.content_detail" v-html="detail.forecast.detail" />
            <div :class="s.content_item">
              <img
                :class="[s.radio, s.item]"
                src="/img/items/radio.png" alt="">
            </div>
          </div>
          <div :class="[s.content, s.advice]">
            <div :class="s.content_label">ちょいくアドバイス</div>
            <div :class="s.content_detail" v-html="detail.advice"/>
            <div :class="s.content_item">
              <img
                :class="[s.pc, s.mame, s.item]"
                src="/img/items/mame.png" alt="">
              <img
                :class="[s.sp, s.takei, s.item]"
                src="/img/items/takei.svg" alt="">
            </div>
          </div>
          <div :class="s.aishou">
            <img :class="s.aishou_title" src="/img/pages/scoring/compatibility.png" alt="">
            <div :class="s.aishou_character_wrap">
              <img :class="s.aishou_heart" src="/img/items/heart.png" alt="">
              <img :class="[s.aishou_character, s[type]]" :src="`/img/characters/gifs/${detail.good_compatibility_type.gif}.gif`" alt="">
              <img :class="s.aishou_logo" src="/img/pages/scoring/good.png" alt="">
              <div :class="[s.aishou_text, s[type]]">{{ detail.good_compatibility_type.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="s.giza_separator"/>
      <!-- シェア（white背景の部分） -->
      <div :class="s.share_container">
        <div :class="s.share">結果をみんなにシェアしよう!</div>
        <div :class="s.btn_container">
          <img :class="s.toge" src="/img/effects/togetoge.png" alt="">
          <div :class="s.btn_wrap">
            <div :class="s.btn_standard">
              <div :class="s.shibon">
                <img src="/img/characters/png/shibon.png" alt="">
              </div>
              <a :href="`http://x.com/share?url=https%3A%2F%2Fchoiku.jp%2Fresult%2F${this.$route.params.type}%2F&text=%E3%81%93%E3%81%93%E3%81%AB10%2C000%E5%86%86%E3%81%AE%E4%BD%BF%E3%81%84%E6%96%B9%E3%82%92%E6%9B%B8%E3%81%84%E3%81%A6%E3%81%AD%0D%0A%E5%85%AC%E5%BC%8F%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%EF%BC%88+%40seeboncho+%EF%BC%89%E3%81%AE%E3%83%95%E3%82%A9%E3%83%AD%E3%83%BC%E3%82%82%E5%BF%98%E3%82%8C%E3%81%9A%E3%81%AB%EF%BC%81%0D%0A%0D%0A%23%E3%81%A1%E3%82%87%E3%81%84%E3%81%8F%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88`">
                <button
                  type="button"
                  :class="s.btn">シェアする</button>
              </a>
            </div>
          </div>
        </div>
        <div :class="s.campaign">
          <div :class="s.campaign_title_sub">アカウントフォロー&シェアで</div>
          <div :class="s.campaign_title"><span class="nowrap">Amazonギフト券</span><br>10,000円分を<span class="nowrap">10名様に</span><br>プレゼント<span :class="s.rotate">!</span></div>
          <div :class="s.campaign_detail">「ちょいくせ未来予報」の結果をみんなにシェアしよう！さらに、<span :class="s.yellow_bold">1万円の使い方を書けば、</span>なんと<span :class="s.yellow_bold">当選確率が２倍に!?</span>ステキな投稿は公式 X でリポスト！ 優秀賞も発表します。</div>
          <img :class="s.campaign_logo" src="/img/pages/scoring/campaign.png" alt="">
        </div>
        <div :class="s.logos">
          <router-link to="/"><img :class="s.gif" src="/img/logos/choiku.gif" alt=""></router-link>
          <router-link to="/"><img src="/img/logos/choikuse.png" alt=""></router-link>
        </div>
        <div :class="s.to_top">
          <router-link to="/">もどる</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ScoringKeyVisual,
} from '../../components/parts';

export default {
  name: 'Scoring',
  components: {
    ScoringKeyVisual,
  },
  props: {},
  data() {
    return {
      flag: {
        isValidtype: false,
      },
      type: null,
      nameTypes: {
        rucola: 'enf',
        ingen: 'isf',
        papurika: 'est',
        ichijiku: 'inf',
        radish: 'esf',
        kiwi: 'int',
        lycoris: 'ist',
      },
      characters: {
        enf: {
          name: 'がんばルッコラ',
          personality: {
            title: '愛すべき、お人よし。',
            detail: '「それ、やっておくね！」が口癖のあなたは、<span class="text_bg">超がつくほどお人よし</span>な性格。まわりから何かを頼まれたら、「断る」なんて言葉は辞書にありません。むしろ、頼まれなくても、困っている人がいたら見過ごすことができない。<span class="text_bg">ヒーロースイッチが自動でオン</span>になり、助けて助けて、<span class="text_bg">助けまくっちゃう</span>。そういう、一度やると決めたらやり通す、<span class="text_bg">ちょっぴり頑固</span>な部分も含め、あなたの魅力なんですよね。だれとでも仲良くしたいと思っているし、だれのことも、分け隔てなく尊敬できる。とくに、<span class="text_bg">人生の先輩ヒーローたち（社長や研究者など、社会を豊かにしている人）のことをリスペクト</span>しまくりで、自叙伝や名言集を読んでは、「じぶんなんて、まだまだ！」って、よりいっそう<span class="text_bg">健気にがんばる</span>。がんばり過ぎて忙しいから、ご飯替わりのエナドリで補給。そんな毎日でも、まわりからの<span class="text_bg">「ありがとう」がなによりのエネルギー</span>なんだよね。お礼の手紙なんてもらった日には、一生の宝ものにしちゃう。',
          },
          forecast: {
            title: '自己犠牲を極めし、本物のヒーロー。',
            detail: '老若男女に、動物に、地球にもやさしいスーパーいい人。そんなあなたは、あまりに自己犠牲の精神が強く、じぶんのことはさておいて人を助けまくる。そしたらある日、なんと<span class="text_bg">本物のヒーローになっていた！</span>みんなはあなたをこう呼ぶ、お困り解決「がんばルッコラマン」と。この世界から困っている人をなくす。その使命のために、24時間パトロール活動。自作のスーツに身を包み、助けを求める声のもとへすぐさま駆けつける。……と言っても、空を飛ぶことはできないので移動は徒歩。悪党を退治する機会はなかなかないから、信号無視とポイ捨ての取り締まりに奮闘。もしかすると、同じ性格の仲間とユニットを組み、<span class="text_bg">「がんばルッコラ戦隊」を結成</span>しているかも。',
          },
          advice: 'じぶんで選んでいるハードモードだけど、見方を変えれば、あなたの人生を充実させている大事なエッセンス。それはステキなことだけど、<span class="text_bg">限界ギリギリまでがんばるだけじゃなく、ある程度は余裕を持って行動できる人になろう！</span>ほら、もしあなたががんばり過ぎて倒れたら、逆にほかのがんばルッコラたちに助けられちゃうよ？',
          good_compatibility_type: {
            name: 'せんさイチジク',
            type: 'inf',
            gif: 'sensaichijiku',
          },
        },
        isf: {
          name: 'さんざインゲン',
          personality: {
            title: '“推し”に沼って、冷めループ。',
            detail: '熱量を注ぎたい相手＝<span class="text_bg">“推し”を、命がけで応援</span>する。それがあなたの最優先事項。対象はアイドルやアニメのキャラかも、はたまたファッションやコスメブランドかも、もしくはリアルな友人の中にいるのかもしれない。推しのためなら、足が遅くても全力ダッシュ！ 激しいダンスの振り付けや外国語の歌詞を覚えたり、大行列に何時間だって並べちゃう。<span class="text_bg">推しとの妄想ワールドでは、どんな苦労も人生のしあわせスパイスになる</span>んだから！ でも悲しいことに、<span class="text_bg">推しにも賞味期限はある</span>んです。ほら思い出して、過ぎ去っていった推したちを。あんなに一生懸命<span class="text_bg">プレミアグッズのために散財した</span>のに、部屋の隅っこにひっそりと置かれているじゃないですか……。まぁ実際のところ、じぶんから冷めているんですけどね。<span class="text_bg">超熱しやすく超冷めやすい</span>。そんなあなたの良いところは、平凡な日々に、じぶんで楽しみを生みだしていけること。言い換えれば、<span class="text_bg">熱量クリエイター</span>なんです。',
          },
          forecast: {
            title: '推し活から、推され活へ。',
            detail: '「そんなに散財してて大丈夫？」。家族や友人から、一度はそんなおせっかいを言われたことがあるよね？ まったく、その通りです。散財ぐせってなかなか治らないし、際限なくお金を使い続けたら、人生はお先真っ暗THE END。そして、ついに推し活資金が尽きてしまったあなたは、その超天然な性格からグググっと方向転換。<span class="text_bg">なぜか、じぶんを推すことに!?</span> これまでの推し活で得た経験を糧に、TikTokやYouTubeで発信をスタート。『推し活ルーティーン』『推しから愛されメイク術』『0円で完成！推し活グッズ』などのメガヒットコンテンツを量産し、<span class="text_bg">インフルエンサー兼クリエイター</span>として、気づけば推す側から推される側に。',
          },
          advice: 'いろいろなことに興味を持つのもいいけど、<span class="text_bg">腰を据えて一つのことをコツコツ続けてみる</span>のはどう？ たとえば推し活に関連する趣味なら、歌やダンス、語学、手芸、ブログを書いてみるなんてのもいいかもしれない。あなたはじぶんが思う以上に不器用じゃないし、持ち前の好奇心を生かせば、なにか一つの分野を極めることもできるはず！ ',
          good_compatibility_type: {
            name: 'チェケラディッシュ',
            type: 'esf',
            gif: 'chiekiradeisshu',
          },
        },
        est: {
          name: 'コスパプリカ',
          personality: {
            title: '表キラキラ、裏ギラギラ。',
            detail: '「都会への憧れ200%」を絵に描いたような、キラキラ生活を送るあなた。ひとたびSNSをひらけば、ランチ、洋服、夜景、コスメ、海外旅行、ナイトプールと、映え写真全開！ そして、もれなく2枚目以降に自撮りが入ってる。フォロワーも多いし、友達も多そうだし、オシャレだし、なんだか<span class="text_bg">毎日充実してそう</span>。人生うまくいっていてうらやましい！ と、<span class="text_bg">まわりからは憧れられている</span>けど、それはあくまでも表の顔……。実際、<span class="text_bg">裏ではかなーーーり計算してます</span>よね。基本的には、<span class="text_bg">買い物も食事も、友人関係ですらコスパ重視</span>。セールが大好き、自炊も大好き、<span class="text_bg">じぶんにメリットのある人にしか時間を使いたくない</span>。でもだらしないところもあって、用事が何もない休日には、すっぴんパジャマのまま一日中ゴロゴロと干物のように過ごしている。部屋も散らかり気味だし、SNSのイメージとの<span class="text_bg">ギャップはすごい</span>。でもまぁ、そういう<span class="text_bg">見栄っぱりなところも可愛い</span>んですけどね。',
          },
          forecast: {
            title: 'そうだ、インドいこう。',
            detail: '近い将来、あなたは<span class="text_bg">インドにいます</span>。理由は簡単です。ズバリ、都会に疲れたから！ SNSで偽りのじぶんを演じ続けた結果、どこかで心に綻びができる。で、ふと思うんです。「あれっ、わたしの幸せって何だっけ？」。憧れで都会に出たものの、気持ちは満たされない。やっぱり、じぶんは庶民的なんだと実感するばかり。でも、さすがに地元には戻りたくないので、<span class="text_bg">幸せの形を求めてインドへ自分探しの旅に</span>。ガンジス川のほとりで貧しくも日々を笑顔で生きる人々を見て、「小さな幸せが積み重なって大きな幸せになるんだ」と悟りを開きました。そして現地生活に馴染んだあなたは、<span class="text_bg">インドでできた友人と一緒に帰国し、カレー屋を開店</span>。まったり暮らしましたとさ。',
          },
          advice: '計算高いは、言い換えれば「計画性がある」ということでもある。表向きは華やかでも、裏では質素で堅実なあなたは、「塵も積もれば山となる」の精神を理解している人。これまで通り、<span class="text_bg">コツコツ目標に向かうことを大切に</span>していきましょう。<span class="text_bg">たまにはじぶんを振り返り、立ちどまってみる</span>のも忘れずにね！  え、余計なお世話だって？',
          good_compatibility_type: {
            name: 'シークレット',
            type: 'ist',
            gif: 'secret',
          },
        },
        inf: {
          name: 'せんさイチジク',
          personality: {
            title: '繊細は、毒にも薬にも。',
            detail: '「今日は頭が重い」って感じたら、低気圧だったり、曇天だったり。そんな経験、何度もしたことあるよね。わかるよ、あなたは繊細さん。<span class="text_bg">まわりが気にしない環境の変化に気づいたり、人の表情や行動から相手の考えが分かっちゃう</span>特殊能力を持っている。だから、<span class="text_bg">共感力が高くて、友達からは「聞き上手」だって信頼されている</span>。それは嬉しいことだけど、たまに<span class="text_bg">共感し過ぎて疲れちゃう</span>のが悩み。<span class="text_bg">人一倍、心のやさしい</span>あなただから、真剣に向き合っちゃうんだよね。そのほかにも、家電量販店で蛍光灯の明るさに目眩がしたり、電車で隣に座った人の香水で頭痛がしたり、相手の気持ちを読み過ぎて<span class="text_bg">言いたいことが言えなかったり</span>、繊細っていうのもなかなか楽じゃないよね。だからこそ、SNSの匿名アカウントに気持ちを書いて、ストレスを発散してるの知ってるよ。決して目立つのは好きじゃないんだけど、<span class="text_bg">しっかりじぶんの意見を持っている</span>。そこも含めて、あなたの個性。',
          },
          forecast: {
            title: '満足度100%の、スーパー占い師。',
            detail: '世の中には、ただ誰かに話を聞いてもらいたい人がたくさんいて、そういう人たちは「こうしなさい」と押しつけられるのが嫌い。それよりも、あなたのようにやさしく話を聞いて、「こうしたいの？」と寄り添ってくれることを求めている。友だちの相談に乗る中で、そんな真理に気づいたあなたは、<span class="text_bg">趣味で占いをスタート</span>。人の多い都心は苦手なので、<span class="text_bg">郊外にあるスーパーの駐車場（自宅の近所）でね</span>。その珍しさから、「じつは、すごい人なんじゃないか」という噂を呼び、<span class="text_bg">SNSでバズって大人気になる</span>。とはいえ、何人もの話を聞くと疲れてしまうので、一日２人限定に。するとそれがまた「予約が取れない」という噂を呼び、なんと3年先まで予約が埋まってしまった。',
          },
          advice: 'あなたが、もしかしたら<span class="text_bg">じぶんの短所だと感じていることも、誰かにとっては長所</span>になるかもしれない。そんな考えを持って、ほんの少し世の中との接点を増やしてみるのはどうだろう？  じぶんにとって<span class="text_bg">心地いい居場所を探したり、できるだけ自然体でいられる時間を増やせば</span>、きっと、あなたの個性をポジティブに活かしていけるはずだよ！',
          good_compatibility_type: {
            name: 'がんばルッコラ',
            type: 'enf',
            gif: 'ganbarukkora',
          },
        },
        esf: {
          name: 'チェケラディッシュ',
          personality: {
            title: '理屈より、バイブス。',
            detail: 'たとえるなら、マグロのよう。立ち止まるより、前を見よう。ヒマな時間は最大の敵。だから出かけるよ、社交的に。<span class="text_bg">フッ軽、どこにだって登場</span>。<span class="text_bg">一目会えば、だれとでも友情</span>。落ち着きないかも、気分屋らしい。それさえも、わたしの性（さが）らしい。<span class="text_bg">勘は鋭い、まるで野生</span>。最後はなんとかなる人生。<span class="text_bg">頭の良さより、ハート重視</span>。こんな生き方、ほんとステキだし？ マネるのはイヤ、自分だけのスタイル。つらぬいてるから、みんなスマイル。ウソつけないが、責任感ない。<span class="text_bg">よく言う、「一生のお願い」</span>。地元の友だち宝もの、でも先輩には頭上がらない。いつも予定は決めない、アドリブウェイで。楽観的でも、少しずつ上へ。主役じゃなくても、<span class="text_bg">エンターテイナー</span>。「オシャレ命です」って、財布いたいな。服もアクセも、ホンモノ志向。買ったら、だれかに見せにいこう。いつでもワイワイ、パーティーピーボー。それがマイウィッシュ、チェケラディッシュ！',
          },
          forecast: {
            title: 'ノリ続けるよ、どこまでも。',
            detail: '「ノリが良い」というのは、人から好かれる才能でもある。それがじぶんの武器だと感じたあなたは、<span class="text_bg">一念発起して芸人の道へ</span>。「センスより、ノリで勝負！」。そんな自信を胸に某芸人養成所へ入学。渾身のネタは、地元の友だちや先輩の“あるあるネタ”をラップし、決め台詞に「マイルドだろぉ？」。このシュールさが好評を博し、<span class="text_bg">一晩で人気芸人の仲間入り！</span> ……と、思ったのも束の間、ノリだけの一発屋として飽きられ、すぐに表舞台からフェードアウト。その後、つらい時期を過ごすことになるが、あなたは決してじぶんのスタイルを曲げなかった。すると数年が経った後、<span class="text_bg">とあるCMに抜擢されたことをきっかけに再ブレイク！</span> 第二次ブームを巻き起こしたのだった。',
          },
          advice: '今を生きるのもいいけど、<span class="text_bg">長い人生には長期目線も大切</span>。大事な決断は、その場のノリや運任せにせず、しっかり悩んで考えることを意識しよう。もし先を見据えた時に違和感を感じたなら、まわりに相談してみるといい。友だちの多いあなたなら、いろいろな視点のアドバイスを活かして、納得のいく答えを導いていけるYO！',
          good_compatibility_type: {
            name: 'さんざインゲン',
            type: 'isf',
            gif: 'sanzaingen',
          },
        },
        int: {
          name: 'けんキウイ',
          personality: {
            title: '天才、はたまたクレイジー。',
            detail: '誰かは忘れたけど、「何かを捨てなければ、何かを得ることはできない」って言ってたよね。けんキウイタイプってまさにそんな人。<span class="text_bg">興味対象を分析したり、より良くするアイディアを考えたり、ユニークな視点を発見する</span>など天賦の才を持つ人が多い。そのかわり、一日中でも<span class="text_bg">空想世界で考え事をしていられる</span>ので、<span class="text_bg">他人にはあまり理解がない</span>し、正直言って興味もない。だから、よく相手の気分を損ねてしまうことがあるんだけど、議論はいつも平行線。だって、どれだけ感情をぶつけられたところで、<span class="text_bg">論理やデータを重視しているあなたにとっては無意味</span>なんだもん。<span class="text_bg">本当はただ冷静なだけ</span>で、人を傷つけたいなんてまったく思ってないんだけどね。基本的には、好きな対象の<span class="text_bg">研究に没頭できていれば満足</span>。ごく稀にノリで生きている人を見て、「こんな人生もいいかも」って一瞬思ったりもするけど、じつは、ただ非論理的な思考や生き方を研究したいと、思っているだけだったりして。',
          },
          forecast: {
            title: 'けんキウイ探偵物語。',
            detail: 'ある日、あなたがいつものように研究に没頭していると、一本の電話が入った。それは知人の警察官からである。「どうしても手に追えないヤマがあるんだ。協力してくれないか？」。はじめは興味のない話だったが、なにやらミステリー小説に出てくるような難解な事件らしい。あなたの好奇心アンテナが強く反応した。そして、<span class="text_bg">持ち前のユニークな視点と分析能力を駆使し、犯人のトリックを見破ることに成功</span>。言い逃れする犯人に向かって、こう言い放った「これってあなたの発想ですよね」。この解決をきっかけに、迷宮入りになりそうな事件の捜査協力依頼がひっきりなしに来るように。こうしてあなたは<span class="text_bg">研究者の傍ら、探偵業をはじめることになった</span>。',
          },
          advice: 'かの有名な天才、レオナルド・ダ・ヴィンチはこう言った。「鉄は手入れをしないと錆びてしまう。同じように、才能も使わなければダメになってしまう」と。 たしかに<span class="text_bg">興味ある分野を極めるために才能を使う道もあるけれど、思い切って視野を広げてみる</span>のはどうだろう。新たな分野×才能で、想像を超える化学反応が起きるかも!?',
          good_compatibility_type: {
            name: 'がんばルッコラ',
            type: 'enf',
            gif: 'ganbarukkora',
          },
        },
        ist: {
          name: 'ミステリコリス',
          personality: {
            title: '一目置かれるレアキャラクター。',
            detail: '幼い頃から<span class="text_bg">「じぶんは人とは違う」という認識があり</span>、世間のしきたりや流行には流されない。マイペースというよりも、<span class="text_bg">じぶんの感性を大切に育んできた</span>あなた。たいていのことはできてしまうし、<span class="text_bg">芸術の才能もあるハイスペックさ</span>で、まわりから常に一目置かれている。良く言うなら、ミステリアスな優等生。悪く言うなら、何を考えているのか分からない謎めいた存在。常識や規則など、だれかのつくった枠組みが苦手で、<span class="text_bg">理由に納得がいかなければ、遠慮なく自由を選ぶ</span>。実はだれよりも公平に判断しているだけなのに「まわりを見下している」と、<span class="text_bg">誤解を生むことも多い</span>のがたまにキズ。だからこそ、<span class="text_bg">集団よりも一人で気ままに行動する方が好き</span>なんですよね。皮肉なことに、そんな姿勢がまた勝手なイメージを生んでしまうのだけど。本当はみんなと同じように、ゲーセンで遊んだり、おそろいのグッズを買いたいとか、そういう気持ちもちょっぴり持っているんですけどね。',
          },
          forecast: {
            title: '謎の覆面作家、文壇デビュー。',
            detail: '才能あふれるあなたは仕事でも趣味でも活躍し、充実した日々を過ごしていますが、なにか足りない……。そんな気持ちを埋めるべく<span class="text_bg">小説を執筆</span>。好きなことを極めたい性分で出版社のコンクールに応募しますが、残念ながら落選します。しかし、運良く編集者が気にかけてくれることに。その数年後、<span class="text_bg">編集者と二人三脚で完成させた小説『川辺のリコリス』でデビュー！</span> しかも100万部以上のベストセラーとなり、映画化決定。愛読者＝リコリストという流行語も生まれ、天才覆面作家の登場に文芸界がざわつきます。その後、著名文化人枠のゲストとして、<span class="text_bg">ニュース番組でコメンテーターを務めるように</span>なりますが、顔出し声出しNGのため、正体がバレることはありませんでした。',
          },
          advice: 'なんでも人並み以上にできるあなたにも、<span class="text_bg">つまずいた時に支えてくれる味方は必要</span>。その方が、人生はもっと輝くよ！ え、どうやってつくればいいのかって？ たとえば、弱みをさらけ出すんですよ。恥ずかしい悩み（ジャンクフードが好き過ぎてやめられない！とか）を相談してみると、愛されギャップと親しみが生まれるかも！',
          good_compatibility_type: {
            name: 'コスパプリカ',
            type: 'est',
            gif: 'kosupapurika',
          },
        },
      },
    };
  },
  created() {
    if (!Number(this.$route.query?.showResult)) this.$router.push('/');
    this.type = this.nameTypes[this.$route.params.type];
    this.flag.isValidtype = Boolean(this.type);
  },
  methods: {},
  computed: {
    detail() {
      return this.characters[this.type] || null;
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  padding-bottom: 120px;
}

$detail-size: var(--font-default);
$label-size: 48px;
$title-size: 32px;
.detail {
  position: relative;
  padding: 190px min(20%, 32px) 24%;
  background-color: var(--primary-green);

  &_container {
    max-width: 1150px;
    margin: 0 auto;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 2vw; /* 波の高さを設定 */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50"><path fill="%2355b96e" stroke="%23000" stroke-width="3" d="M0,25 Q25,0 50,25 T100,25 V50 H0 Z"/></svg>');
    background-repeat: repeat-x;
    background-size: calc(100% / 15) 100px;
    background-position: calc(100% / 15 * .25);
    z-index: 10;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 2vw; /* 波の高さを設定 */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50"><path fill="%2355b96e" d="M0,25 Q25,0 50,25 T100,25 V50 H0 Z"/></svg>');
    background-repeat: repeat-x;
    background-size: calc(100% / 15) 100px;
    background-position: calc(100% / 15 * .25);
    z-index: 20;
  }
}
.content {
  position: relative;
  padding: 85px 85px;
  background-color: var(--bg-yellow);
  border: 1px solid var(--primary-black);
  border-radius: 12px;
  margin-top: 82.6px;

  &_label {
    font-weight: 700;
    font-size: $label-size;
    padding: 20px 168px;
    white-space: nowrap;
    border-radius: 100px;
    background-color: var(--primary-black);
    color: var(--primary-white);
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -70%);
  }

  &_title {
    font-weight: 700;
    font-size: $title-size;
    text-align: center;
  }

  &_detail {
    margin-top: 15px;
    font-size: $detail-size;
    font-weight: 600;
    line-height: 1.8;
  }

  &.forecast, &.advice {
    margin-top: 213px;
  }

  &.forecast {
    background-color: var(--bg-pink);
  }

  &.advice {
    background-color: var(--bg-green);
    .content_detail {
      margin-top: 0;
    }
  }

  .item {
    position: absolute;
  }

  .uchiwa {
    top: 0;
    left: 0;
    width: 20%;
    transform-origin: top left;
    transform: rotate(-15deg) translate(0, -50%);
  }
  .takei {
    bottom: 0;
    right: 0;
    width: 25%;
    transform-origin: bottom right;
    transform: translate(0, 60%);
  }
  .radio {
    top: 100%;
    left: 0;
    width: 20%;
    transform: translate(-10%, -20%);
  }
  .mame {
    top: 100%;
    right: 0;
    width: 20%;
    transform-origin: top right;
    transform: rotate(10deg) translate(10%, -20%);
  }
}

.aishou {
  margin-top: 155px;
  &_title {
    width: 53vw;
    max-width: 600px;
    min-width: 117px;
    display: block;
    margin: 0 auto;
  }
  &_character_wrap {
    position: relative;
    width: 64vw;
    max-width: 800px;
    margin: 0 auto;
  }
  &_heart {
    height: 100%;
    padding-top: 10%;
    padding-right: 10%;
    width: 100%;
    object-fit: contain;
    background-position: center;
  }
  &_character {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    width: 100%;
    height: 100%;
    padding-bottom: 12px;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
    &.esf {
      width: 120%;
      height: 120%;
    }
    &.enf {
      width: 130%;
      height: 130%;
      top: 55%;
    }
    &.est {
      width: 90%;
      height: 90%;
    }
    &.isf {
      width: 100%;
      height: 100%;
    }
    &.ist {
      width: 120%;
    }
  }
  &_logo {
    position: absolute;
    width: 35vw;
    max-width: 437px;
    min-width: 112px;
    bottom: 0;
    left: 50%;
    transform: translate(-85%, 60%);
  }
  &_text {
    position: absolute;
    top: 25%;
    right: 15%;
    transform: translate(40%, 0) rotate(10deg);
    transform-origin: center;
    font-size: 35px;
    font-weight: 700;
    &.enf { // 相性:せんさイチジク
      top: 72%;
      right: 8%;
    }
    &.esf { // 相性: さんざインゲン
      top: 72%;
      right: 8%;
    }
    &.est { // 相性: ミステリコリス
      top: 78%;
      right: 13%;
    }
    &.inf { // 相性: がんばルッコラ
      top: 40%;
      right: 8%;
    }
    &.isf { // 相性: チェケラディッシュ
      top: 85%;
      right: 8%;
    }
    &.int { // 相性: がんばルッコラ
      top: 40%;
      right: 8%;
    }
    &.ist { // 相性: コスパプリカ
      top: 91%;
      right: 18%;
    }
  }
}

.giza_separator {
  position: relative;
  width: 100%;
  $giza-h: 80px;
  &::before {
    z-index: 20;
    content: "";
    position: absolute;
    top: 0; /* ギザギザの高さを調整 */
    left: 0;
    width: 100%;
    height: $giza-h; /* ギザギザの高さ */
    background-image: url('/img/effects/giza_parts.png');
    background-size: 160px $giza-h;
    background-position: center;
    transform: translateY(calc(-100% + 1px));
  }
}

.share_container {
  background-color: var(--primary-white);
  padding-top: 130px;

  .share {
    text-align: center;
    font-weight: 700;
    font-size: 70px;
    transform-origin: center;
    transform: rotate(-5deg);
    margin-bottom: 32px;
  }
}

.btn_container {
  display: flex;
  justify-content: center;
  position: relative;

  .toge {
    width: 118%;
    max-width: 1000px;
  }
  .btn_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .btn_standard {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    .btn {
      border-width: 1px;
      white-space: nowrap;
      position: relative;
      z-index: 10;
      min-width: 152px;
      border: 2.5px solid var(--primary-black);
      border-radius: 100px;
      padding: 18px 110px;
      background-color: var(--primary-white);
      font-size: 38px;
      font-weight: 700;
      color: var(--primary-black);
      transition: all .3s;
    }
    &::before {
      z-index: 5;
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      background-color: var(--primary-yellow);
      background-image: url('/img/effects/dot_backimage.png');
      border-radius: 100px;
      background-size: 150px;
      top: 12px;
      left: 12px;
      border: 2.5px solid var(--primary-black);
    }
    .shibon {
      pointer-events: none;
      position: absolute;
      object-fit: contain;
      right: 0px;
      bottom: 50%;
      transform-origin: center bottom;
      transform: rotate(25deg) translate(20px, -30px);
      z-index: 1;
      height: fit-content;
      > img {
        width: 150px;
      }
    }
    &:hover {
      .btn {
        transform: translate(12px, 12px);
      }
    }
  }
}

.campaign {
  width: 81%;
  max-width: 1126px;
  background-image: url('/img/pages/scoring/scoring_mokumoku.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  padding: 94px 110px;
  margin: 0 auto;
  &_title {
    font-size: 72px;
    font-weight: 700;
    line-height: 1.4;
  }
  &_title_sub {
    font-size: 42px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    margin: 0 0 40px;
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      padding-top: percentage(7 / 186);
      background-image: url('/img/pages/specialcampaign/title_sub_line.svg');
      top: 100%;
      left: 0;
    }
  }
  &_detail {
    font-size: var(--font-default);
    font-weight: 600;
    vertical-align: middle;
    margin-top: 24px;
  }
  span {
    display: inline-block;
    &.rotate {
      transform-origin: bottom center;
      transform: rotate(15deg);
    }
    &.space {
      letter-spacing: 5px;
    }
    &.yellow_bold {
      font-weight: 700;
      line-height: 1;
      background-color: var(--primary-yellow);
    }
  }

  &_logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(5deg);
    width: 52%;
    max-width: 608px;
    min-width: 167px;
  }
}

.logos {
  margin: 20px auto 0;
  width: 86%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    object-fit: contain;
    &:nth-child(1) {
      width: 43%;
    }
    &:nth-child(2) {
      width: 54%;
    }
    > img {
      width: 100%;
    }
  }
}

.to_top {
  margin: 12px auto 0;
  position: relative;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  transition: all .3s;
  > a {
    position: relative;
    z-index: 2;
    display: inline-block;
    border: 2px solid var(--primary-black);
    background-color: var(--primary-white);
    font-size: 38px;
    font-weight: bold;
    padding: 16px 72px;
    border-radius: 200px;
  }
  &::before {
    border-radius: 200px;
    border: 2px solid var(--primary-black);
    z-index: 1;
    position: absolute;
    background-image: url('/img/effects/dot_backimage.png');
    background-color: var(--primary-yellow);
    background-size: 200px;
    top: 8px;
    left: 8px;
    height: 100%;
    width: 100%;
    content: '';
    transition: all .3s;
  }

  &:hover {
    transform: translate(8px, 8px);
    &::before {
      top: 0;
      left: 0;
    }
  }
}

@include mdView {
  $detail-size: var(--font-default-md);
  $label-size: 36px;
  $title-size: 36px;

  .detail {
    padding: 120px min(20%, 32px) 24%;
    &_container {
      max-width: 800px;
    }
  }
  .content {
    padding: 48px 60px;
    margin-top: 60px;

    &_label {
      font-size: $label-size;
      padding: 8px 120px;
    }
    &_title {
      font-size: $title-size;
    }
    &_detail {
      font-size: $detail-size;
    }
    &.forecast, &.advice {
      margin-top: 155px;
    }
  }
  .aishou {
    margin-top: 130px;
    &_text {
      font-size: 28px;
    }
  }

  .giza_separator {
    $giza-h: 50px;
    &::before {
      height: $giza-h; /* ギザギザの高さ */
      background-size: 100px $giza-h;
    }
  }

  .share_container {
    padding-top: 100px;
    .share {
      font-size: 42px;
      margin-bottom: 24px;
    }
  }

  .btn_container {
    .btn_standard {
      .btn {
        padding: 12px 90px;
        font-size: 28px;
      }

      &::before {
        background-size: 100px;
        top: 8px;
        left: 8px;
        border: 2.5px solid var(--primary-black);
      }

      .shibon {
        > img {
          width: 120px;
        }
      }

      &:hover {
        .btn {
          transform: translate(8px, 8px);
        }
      }
    }
  }

  .campaign {
    padding: 80px 75px;
    &_title {
      font-size: 42px;
    }
    &_title_sub {
      font-size: 28px;
      margin: 0 0 30px;
    }
    &_detail {
      font-size: var(--font-default-md);
      margin-top: 18px;
    }
  }
  .to_top {
    > a {
      font-size: 28px;
      padding: 12px 72px;
    }
  }
}

@include smView {
  $detail-size: 18px;
  $label-size: 24px;
  $title-size: 24px;
  .wrap {
    padding-bottom: 60px;
  }
  .detail {
    padding: 40px min(20%, 32px) 24%;
  }
  .content {
    padding: 48px 42px 42px;
    &_label {
      font-size: $label-size;
      padding: 4px 80px;
    }
    &_title {
      font-size: $title-size;
    }
    &_detail {
      font-size: $detail-size;
    }
    &.forecast, &.advice {
      margin-top: 125px;
    }
    .uchiwa {
      top: 100%;
      left: auto;
      right: 0;
      transform-origin: bottom right;
      transform: rotate(-15deg) translate(10%, -35%);
    }
    .takei {}
  }
  .aishou {
    margin-top: 70px;
    &_text {
      font-size: 22px;
      &.enf { // 相性:せんさイチジク
        top: 72%;
        right: 3%;
      }
      &.esf { // 相性:さんざインゲン
        top: 72%;
        right: 5%;
      }
      &.est { // 相性:ミステリコリス
        top: 80%;
        right: 9%;
      }
      &.inf { // 相性: がんばルッコラ
        top: 35%;
        right: 6%;
      }
      &.isf { // 相性: チェケラディッシュ
        top: 80%;
        right: 6%;
      }
      &.int { // 相性: がんばルッコラ
        top: 35%;
        right: 6%;
      }
      &.ist { // 相性: コスパプリカ
        top: 91%;
        right: 18%;
      }
    }
  }
  .giza_separator {
    $giza-h: 30px;
    &::before {
      height: $giza-h; /* ギザギザの高さ */
      background-size: 60px $giza-h;
    }
  }
  .share_container {
    padding-top: 60px;
    .share {
      font-size: 28px;
    }
  }
  .btn_container {
    .toge {
      max-width: 640px;
    }
    .btn_standard {
      .btn {
        padding: 8px 70px;
        font-size: 22px;
        border-width: 1px;
      }

      &::before {
        background-size: 100px;
        border-width: 1px;
        top: 8px;
        left: 8px;
      }

      .shibon {
        transform: rotate(25deg) translate(20px, -20px);
        > img {
          width: 100px;
        }
      }
    }
  }
  .campaign {
    padding: 50px 45px;
    &_title {
      font-size: 24px;
    }
    &_title_sub {
      font-size: 20px;
      margin: 0 0 20px;
    }
    &_detail {
      font-size: var(--font-default-sm);
      margin-top: 18px;
    }
  }
  .to_top {
    > a {
      font-size: 22px;
      padding: 4px 56px;
      border-width: 1px;
    }

    &::before {
      border-width: 1px;
      top: 4px;
      left: 4px;
      background-size: 100px;
    }

    &:hover {
      transform: translate(0, 0);
      &::before {
        top: 4px;
        left: 4px;
      }
    }
  }
}

@include xsView {
  $detail-size: 16px;
  $label-size: 14px;
  $title-size: 18px;
  .wrap {
    padding-bottom: 35px;
  }
  .detail {
    padding: 25px min(20%, 32px) 24%;
  }
  .content {
    padding: 24px 15px;
    margin-top: 40px;
    &_label {
      padding: 2px 40px;
      font-size: $label-size;
    }
    &_title {
      font-size: $title-size;
    }
    &_detail {
      font-size: $detail-size;
    }
    &.forecast, &.advice {
      margin-top: 80px;
    }
  }
  .aishou {
    margin-top: 38px;
    &_text {
      font-size: 16px;
      &.enf { // 相性:せんさイチジク
        top: 80%;
        right: 7vw;
      }
      &.esf { // 相性:さんざインゲン
        top: 75%;
        right: 9%;
      }
      &.est { // 相性:ミステリコリス
        top: 80%;
        right: 12%;
      }
      &.inf { // 相性: がんばルッコラ
        top: 37%;
      }
      &.isf { // 相性: チェケラディッシュ
        top: 75%;
        right: 6.8%;
      }
      &.int { // 相性: がんばルッコラ
        top: 37%;
      }
      &.ist { // 相性: コスパプリカ
        top: 91%;
        right: 18%;
      }
    }
  }

  .giza_separator {
    $giza-h: 15px;
    &::before {
      height: $giza-h; /* ギザギザの高さ */
      background-size: 30px $giza-h;
    }
  }

  .share_container {
    padding-top: 27px;

    .share {
      font-size: 17px;
      margin-bottom: 12px;
    }
  }

  .btn_container {
    .btn_standard {
      .btn {
        padding: 6px 40px;
        font-size: 14px;
      }

      &::before {
        background-size: 60px;
        border-width: 1px;
        top: 4px;
        left: 4px;
      }

      .shibon {
        transform: rotate(25deg) translate(15px, -10px);
        > img {
          width: 55px;
        }
      }

      &:hover {
        .btn {
          transform: translate(4px, 4px);
        }
      }
    }
  }

  .campaign {
    width: 81%;
    max-width: 587px;
    background-image: url('/img/pages/scoring/scoring_mokumoku.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;
    padding: 25px;
    margin: 0 auto;
    &_title {
      font-size: 19px;
      font-weight: 700;
      line-height: 1.4;
    }
    &_title_sub {
      font-size: 16px;
      margin: 0 0 15px;
    }
    &_detail {
      font-size: var(--font-default-xs);
      font-weight: 600;
      vertical-align: middle;
      margin-top: 15px;
    }
    span {
      display: inline-block;
      &.rotate {
        transform-origin: bottom center;
        transform: rotate(15deg);
      }
      &.space {
        letter-spacing: 5px;
      }
      &.yellow_bold {
        font-weight: 700;
        line-height: 1;
        background-color: var(--primary-yellow);
      }
    }

    &_logo {
      position: absolute;
      top: 0;
      left: 50%;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(5deg);
      width: 52%;
      max-width: 608px;
      min-width: 167px;
    }
  }

  .logos {
    margin: 20px auto 0;
    width: 86%;
    max-width: 1175px;
    display: flex;
    justify-content: space-between;
    > * {
      object-fit: contain;
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 54%;
      }
    }
  }

  .to_top {
    > a {
      font-size: 14px;
    }
    &::before {
      background-size: 70px;
    }
  }
}

.pc {
  @include smView {
    display: none;
  }
}

.sp {
  display: none;
  @include smView {
    display: block;
  }
}
</style>

<style lang="scss">
.text_bg {
  background-color: var(--primary-yellow);
}
</style>
