<template>
  <div :class="s.wrap">
    <div :class="s.content">
      <div
        :class="[s.drawer, flag.isShow ? s.isActive : '']">
        <ul :class="s.items">
          <li
            v-for="(item, i) in items" :key="i"
            :class="flag.isShow ? s.show : s.hide">
            <div :class="s.bg" />
            <a
              :href="item.link"
              @click="flag.isShow = false"
              :class="s.item">{{ item.label }}</a>
          </li>
        </ul>
      </div>

      <div
        @click="flag.isShow = !flag.isShow"
        :class="s.btn_wrap">
        <div
          :class="[s.btn, flag.isShow ? s.isActive : '']">
          <span :class="s.first"/>
          <span :class="s.second"/>
          <span :class="s.third"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: {
        isShow: false,
      },
      items: [
        {
          label: 'About',
          link: '/#about',
        },
        {
          label: 'Illustrator',
          link: '/#artists',
        },
        {
          label: 'Movie',
          link: '/#movie',
        },
        {
          label: 'Campaign',
          link: '/#campaign',
        },
        {
          label: 'gallery',
          link: '/#gallery',
        },
      ],
    };
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 50;
}
.content {
  position: relative;
}
.drawer {
  position: fixed;
  top: 0;
  right: 0;
  padding-right: 18px;
  padding-top: 100px;
  transform: translateX(0);
  transition: transform 0.3s ease;
  pointer-events: none;
  &.isActive {
    pointer-events: auto;
  }
}
.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  > li {
    position: relative;
    &.hide {
      opacity: 0;

      transform: scale(0);
      transition: all .3s;
    }
    &.show {
      opacity: 1;
      transform: scale(1);
      animation: scaleUp .3s linear;
    }
    &:not(:first-child) {
      margin-top: 12px;
    }

    .item {
      position: relative;
      width: 120px;
      height: 120px;
      font-size: 18px;
      border: 2px solid var(--primary-black);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      background-color: var(--primary-white);
      z-index: 2;
    }

    .bg {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 120px;
      height: 120px;
      border: 2px solid var(--primary-black);
      border-radius: 50%;
      background-image: url('/img/effects/dot_backimage.png');
      background-size: cover;
      background-color: var(--primary-yellow);
      z-index: 0;
    }
  }
}

$wh: 75px;
.btn {
  &_wrap {
    position: absolute;
    top: calc((var(--header-h) - var(--drawer-menu-btn-wh)) / 2);
    right: 6px;
  }
  position: relative;
  width: $wh;
  height: $wh;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: $wh;
  transform-origin: center;
  transition: all .3s;

  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc($wh * .5);
    height: 3px;
    background-color: #000;
    transform-origin: center;
    transition: all .3s;
    border-radius: 12px;

    &.first {
      transform: translate(-50%, calc(-50% + 8px));
    }
    &.second {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    &.third {
      transform: translate(-50%, calc(-50% - 8px));
    }
  }

  &:hover {
    span {
      width: calc($wh * .6);
      @include smView {
        width: calc($wh * .5);
      }
    }
  }

  &.isActive {
    span {
      &.first {
        transform: translate(-50%, -50%) rotate(calc(45deg + 180deg));
      }
      &.second {
        opacity: 0;
      }
      &.third {
        transform: translate(-50%, -50%) rotate(calc(-45deg - 180deg));
      }
    }
  }
}

@include mdView {
  .drawer {
    padding-right: 12px;
    padding-top: 75px;
    &.isActive {
      pointer-events: auto;
    }
  }
  .items {
    > li {
      &:not(:first-child) {
        margin-top: 12px;
      }

      .item {
        width: 90px;
        height: 90px;
        font-size: 14px;
      }

      .bg {
        top: 5px;
        left: 5px;
        width: 90px;
        height: 90px;
      }
    }
  }

  .btn {
    $wh: 60px;
    &_wrap {
      top: calc((var(--header-h) - var(--drawer-menu-btn-wh)) / 2);
      right: 6px;
    }
    width: $wh;
    height: $wh;
    border-radius: $wh;

    span {
      height: 2px;
      width: calc($wh * .5);

      &.first {
        transform: translate(-50%, calc(-50% + 5px));
      }
      &.third {
        transform: translate(-50%, calc(-50% - 5px));
      }
    }
    &:hover {
      span {
        width: calc($wh * .6);
        @include smView {
          width: calc($wh * .5);
        }
      }
    }
  }
}

@include smView {
  .drawer {
    padding-right: 12px;
    padding-top: 54px;
    &.isActive {
      pointer-events: auto;
    }
  }
  .items {
    > li {
      &:not(:first-child) {
        margin-top: 12px;
      }

      .item {
        width: var(--drawer-menu-item-wh);
        height: var(--drawer-menu-item-wh);
        font-size: 10px;
        border: 1px solid;
      }

      .bg {
        top: 4px;
        left: 3px;
        width: var(--drawer-menu-item-wh);
        height: var(--drawer-menu-item-wh);
        border: 1px solid var(--primary-black);
      }
    }
  }

  .btn {
    $wh: var(--drawer-menu-btn-wh);
    &_wrap {
      top: calc((var(--header-h) - var(--drawer-menu-btn-wh)) / 2);
      right: 6px;
    }
    width: $wh;
    height: $wh;
    border-radius: $wh;
    border-width: 1px;

    span {
      height: 1px;
      width: calc($wh * .5);

      &.first {
        transform: translate(-50%, calc(-50% + 3px));
      }
      &.third {
        transform: translate(-50%, calc(-50% - 3px));
      }
    }
    &:hover {
      span {
        width: calc($wh * .6);
        @include smView {
          width: calc($wh * .5);
        }
      }
    }
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>

