<template>
  <div :class="s.top">
    <div :class="s.content">
      <!-- トップ見出し -->
      <div :class="s.kv">
        <top-kv />
      </div>
      <section>
        <!-- 流れるテキスト -->
        <div :class="s.flow_text" @click="$router.push('/miraiyohou/')">
          <ul
            :class="s.loop_area"
            v-for="loop in 2" :key="loop">
            <li :class="s.flow_text_body"
              v-for="content in 5" :key="content">ちょいくせのある野菜たちの日常を「X」で発信中！フォローよろしくね！</li>
          </ul>
        </div>
      </section>
      <!-- 未来予報 -->
      <future-forecast
        style="z-index: 20;"
        id="future-forecast" />
      <!-- ちょいくプロジェクトとは？ -->
      <what-choiku
        style="z-index: 15;"
        id="about" />
      <!-- illustrator -->
      <guest-artists
        style="z-index: 10;"
        id="artists" />
      <!-- special movie -->
      <special-movie
        style="z-index: 5;"
        id="movie" />
      <!-- special campaign -->
      <special-campaign
        style="z-index: 2;"
        id="campaign" />
      <!-- choiku gallery -->
      <gallery
        style="z-index: 1;"
        id="gallery" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  FutureForecast,
  SpecialMovie,
  SpecialCampaign,
  WhatChoiku,
  GuestArtists,
  Gallery,
  TopKv,
} from '@/components/layouts';

export default {
  name: 'Top',
  components: {
    FutureForecast,
    SpecialMovie,
    SpecialCampaign,
    WhatChoiku,
    GuestArtists,
    Gallery,
    TopKv,
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  created() {
    console.log('hello im topPage');
  },
  methods: {
  },
};
</script>


<style lang="scss" module="s">
.top {
  position: relative;
  z-index: 1;
  width: 100%;
  .content {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .campaign {
    z-index: 10;
  }
}

.pc {
  @include smView {
    display: none;
  }
}

.sp {
  display: none;
  @include smView {
    display: block;
  }
}

.flow_text {
  display: flex;
  font-size: 37px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  border-top: 2px solid var(--primary-black);
  border-bottom: 2px solid var(--primary-black);
  padding: 16px 0;
  &_body {
    margin-right: 1em;
  }
  .loop_area {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    // margin-left: 10px;
    animation: loop-left 90s infinite linear 1s both;
  }
  @keyframes loop-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
}

@include mdView {
  .flow_text {
    font-size: 24px;
    padding: 12px;
  }
}
@include smView {
  .flow_text {
    font-size: 18px;
    padding: 10px;
  }
}
@include xsView {
  .flow_text {
    padding: 8px;
    font-size: 14px;
  }
}
</style>
