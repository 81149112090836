<template>
  <div :class="s.wrap">
    <div :class="s.container">
      <div>
        <img src="/img/pages/specialmovie/movie_logo.png" alt="">
      </div>
      <div :class="s.movie_wrap">
        <img :class="s.movie_frame" src="/img/effects/movie_frame.svg" alt="">
        <iframe
          :class="s.movie"
          src="https://www.youtube.com/embed/pfBN7VFYm6I?si=HasnSAzBXddGxuOo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen />
      </div>
      <div :class="s.title">
        <div>新しいもの好きの女子高生5人が<br>「ちょいくせ未来予報」をやってみた！</div>
      </div>
      <div :class="s.description_wrap">
        <div :class="s.description_img">
          <img src="/img/pages/specialmovie/cinderella.png" alt="">
        </div>
        <div :class="s.description">
          <div>チームシンデレラ</div>
          <div>「未来をもっと面白く！」をコンセプトに活動する、日本最大級のJC・JK・JDの文化祭「シンデレラフェス」を主催する女子チーム。約100名のJKを中心とした、10代の女子学生で構成。主催イベントの企画・運営や、企業や学校とコラボしてイベント・プロジェクトなどを行う。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
};
</script>

<style lang="scss" module="s">
.wrap {
  position: relative;
  background-color: var(--primary-green);
  border-top: 2px solid var(--primary-black);
  padding: 142px 0 170px;
  background-image: 
    url('/img/deco/deco_1.svg'),
    url('/img/deco/deco_22.svg'),
    url('/img/deco/deco_23.svg');
  background-size: 13%, 9%, 20%;
  background-position:
    top -1px right -1px,
    bottom 80px right 80px,
    bottom 60% right 95%;
  background-repeat: no-repeat, no-repeat, no-repeat;
}

.container {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto 0;
}

.movie_wrap {
  margin-top: 60px;
  position: relative;
  height: fit-content;
}

.movie {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 89.3%;
  height: 82%;
  transform: translate(-50.55%, -50.9%);
  margin: 0 auto;
  z-index: 1;
}

.movie_frame {
  position: relative;
  pointer-events: none;
  z-index: 2;
}


.title {
  background-color: var(--primary-yellow);
  font-weight: 700;
  text-align: center;
  font-size: 38px;
  padding: 30px 72px;
  border: 1px solid var(--primary-black);
  border-radius: 100px;
  width: fit-content;
  margin: 18px auto 0;
}
.description {
  &_wrap {
    width: 72%;
    margin: 0 auto;
    position: relative;
  }
  &_img {
    position: absolute;
    width: 30%;
    bottom: 50%;
    left: 0;
    transform: translate(-100%, 0) rotate(-20deg);
    transform-origin: bottom;
  }
  margin-top: 48px;
  > * {
    &:first-child {
      font-size: 40px;
      font-weight: 700;
    }
    &:nth-child(2) {
      font-size: var(--font-default);
      font-weight: 600;
    }
  }
}

@include mdView {
  .wrap {
    padding: 142px 0 100px;
  }
  .title {
    font-size: 24px;
  }
  .description {
    margin-top: 24px;
    > * {
      &:first-child {
        font-size: 32px;
      }
      &:nth-child(2) {
        font-size: var(--font-default-md);
      }
    }
  }
}

@include smView {
  .wrap {
    padding: 45px 0 75px;
    background-size: 13%, 9%, 15%;
    background-position:
      top -1px right -1px,
      bottom 20px right 20px,
      bottom 15px right calc(9% + 20px + 5%);
  }
  .movie_wrap {
    margin-top: 6px;
  }
  .title {
    font-size: 13px;
    padding: 10px 30px;
  }
  .description {
    &_wrap {
      width: 90%;
    }
    &_img {
      position: relative;
      float: left;
      transform: translate(-20%, -35%) rotate(-10deg);
      width: 80px;
      shape-outside: polygon(90% 0, 90% 50%, 40% 70%, 0 80%, 0 0);
    }
    > * {
      &:first-child {
        font-size: 22px;
      }
      &:nth-child(2) {
        font-size: var(--font-default-sm);
      }
    }
  }
}

@include xsView {
  .title {
    font-size: 12px;
  }
  .description {
    > * {
      &:nth-child(2) {
        font-size: var(--font-default-xs);
      }
    }
  }
}
</style>
