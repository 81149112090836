<template>
  <div>
    <header :class="s.wrap" v-if="isShowHeader">
      <div :class="s.logo" class="poyon">
        <router-link
          to="/"
          @click="scroll2Top"><img src="/img/pages/logo_sp.png" alt=""></router-link>
      </div>
      <component v-bind:is="headTag">
        <ul :class="s.header_menu">
          <li v-for="menu in menus" :key="menu">
            <a :href="menu.link"><flip-text :flipping-text="menu.label" /></a>
          </li>
        </ul>
      </component>
      <div :class="s.bubble_wrap">
        <div :class="s.character">
          <img src="/img/characters/png/shibon.png" alt="キャラクター1">
        </div>
        <div :class="s.bubble">
          <div :class="[s.toge, s.bg]"/>
          <div :class="[s.toge, s.bdr]"/>
          <div :class="s.news_flow">
            <ul
              :class="s.loop_area"
              v-for="loop in 2" :key="loop">
              <li :class="s.news_flow_body"
                v-for="content in 5" :key="content">診断してみてね〜！</li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import FlipText from '@/components/parts/FlipText.vue';

export default {
  name: 'GlobalHeader',
  components: {
    FlipText,
  },
  data() {
    return {
      menus: [
        {
          label: 'Movie',
          link: '/#movie',
        },
        {
          label: 'Campaign',
          link: '/#campaign',
        },
        {
          label: 'About',
          link: '/#about',
        },
        {
          label: 'Illustrator',
          link: '/#artist',
        },
        {
          label: 'Gallery',
          link: '/#gallery',
        },
      ],
    };
  },
  computed: {
    headTag() {
      return this.$route.name === 'Home' ? 'h1' : 'p';
    },
    isShowHeader() {
      const hideHeaderPage = ['Scoring', 'Diagnosis'];
      return !hideHeaderPage.includes(this.$route.name);
    },
  },
  created() {
  },
  methods: {
    scroll2Top() {
      const scrollY = window.scrollY;
      window.scrollTo(0, -scrollY);
    },
  },
};
</script>

<style lang="scss" module="s">
$logo-w: 382px;
$charactor-w: 70px;
.wrap {
  background-color: var(--primary-white);
  border-bottom: var(--primary-black) solid 1px;
  height: 100px;
  position: relative;
}
.logo { // 実際には画像が入る
  position: fixed;
  top: 27px;
  left: 43px;
  z-index: 100;
  width: $logo-w;
  img {
    width: 100%;
  }
}

.header_menu {
  display: flex;
  position: absolute;
  right: calc(566px);
  font-weight: 700;
  top: 50%;
  transform: translateY(-50%);
  z-index: 110;
  > li {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  @media (max-width: 1520px) {
    display: none;
  }
}

.news_box {
  position: relative;
  display: flex;
  height: var(--header-h);

}

.character {
  position: absolute;
  top: 10px;
  right: calc(20px + 366px);
  z-index: 10;
  width: $charactor-w;
  img {
    width: 100%;
  }
}

.bubble {
  &_wrap {
    position: absolute;
    top: calc((100px - 35px) / 2);
    right: calc(12px + 75px + 9px);
  }
  width: 368px;
  white-space: nowrap;
  line-height: 1;
  background: var(--primary-pink);
  border: 1px solid var(--primary-black);
  border-radius: 2px;
  z-index: 50;
}
.toge {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 4px;
  &.bg {
    bottom: -6px;
    z-index: 50;
    right: calc(100% - 4px);
    border-color: transparent transparent transparent var(--primary-black);
    transform: rotate(29deg) scale(1.3);
  }
  &.bdr {
    z-index: 51;
    bottom: -4px;
    right: calc(100% - 5px);
    border-color: transparent transparent transparent var(--primary-pink);
    transform: rotate(29deg);
  }
}

.news_flow {
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  height: 35px;
  z-index: 52;
  .loop_area {
    color: white;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    vertical-align: middle;
    animation: loop-left 20s infinite linear 1s both;
  }
  @keyframes loop-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
}

@include mdView {
  $logo-w: 200px;
  $charactor-w: 50px;
  .wrap {
    height: 85px;
  }
  .logo { // 実際には画像が入る
    top: 16px;
    left: 15px;
    z-index: 100;
    width: $logo-w;
  }

  .news_box {
    height: var(--header-h);

  }

  .character {
    top: 5px;
    right: calc(16px + 220px);
    width: $charactor-w;
  }

  .bubble {
    &_wrap {
      top: calc((85px - 28px) / 2);
      right: calc(12px + 60px + 9px);
    }
    width: 220px;
    border: 1px solid var(--primary-black);
    border-radius: 2px;
  }
  .toge {
    border-width: 7px 4px;
    &.bg {
      bottom: -6px;
      right: calc(100% - 4px);
      transform: rotate(29deg) scale(1.3);
    }
    &.bdr {
      bottom: -4px;
      right: calc(100% - 5px);
      transform: rotate(29deg);
    }
  }

  .news_flow {
    font-size: 16px;
    height: 28px;
  }
}

@include smView {
  $logo-w: 130px;
  $charactor-w: 30px;
  .wrap {
    border-bottom: var(--primary-black) solid 1px;
    height: var(--header-h);
  }
  .logo { // 実際には画像が入る
    top: 16px;
    left: 15px;
    z-index: 100;
    width: $logo-w;
  }

  .news_box {
    height: var(--header-h);

  }

  .character {
    top: 5px;
    right: calc(130px + 6px);
    width: $charactor-w;
  }

  .bubble {
    &_wrap {
      top: 14px;
      right: calc(6px + var(--drawer-menu-btn-wh) + 9px);
    }
    width: 130px;
    border: 1px solid var(--primary-black);
    border-radius: 2px;
  }
  .toge {
    border-width: 7px 4px;
    &.bg {
      bottom: -6px;
      right: calc(100% - 4px);
      transform: rotate(29deg) scale(1.3);
    }
    &.bdr {
      bottom: -4px;
      right: calc(100% - 5px);
      transform: rotate(29deg);
    }
  }

  .news_flow {
    font-size: 12px;
    font-weight: 700;
    height: 20px;
  }
}
</style>

<style lang="scss">
.poyon {
  animation: poyon 3s infinite;
}
</style>
