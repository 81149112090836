<template>
  <div :class="s.wrap">
    <div :class="s.bg"/>
    <div :class="s.content">
      <div :class="s.choikuse">
        <img src="/img/logos/choikuse_bg_transparent.svg" alt="">
      </div>
      <div :class="fill1 ? s.urchin1 : s.circle_nofill" />
      <div :class="fill2 ? s.urchin2 : s.circle_nofill" />
      <div :class="fill3 ? s.urchin3 : s.circle_nofill" />
      <div :class="fill4 ? s.urchin4 : s.circle_nofill" />

      <div :class="s.seebon_wrap">
        <div :class="wateringCanState ? s.wateringCan_active : s.wateringCan">
          <img v-if="wateringCanState" :src="wateringCanState" alt="">
        </div>
        <div :class="s.q_number">
          <img :src="`/img/question/asset${scores.length+1}.svg`" alt="">
        </div>
        <div :class="s.seebon_comment">
          <img :class="commentAnimation ? s.bounce : s.nobounce" alt="" :src="seebonComment" @animationend="resetAnimation">
        </div>
        <img
          :class="s.seebon"
          :src="seebonState ? seebonState : `/img/characters/gifs/seebon_question_0${scores.length % 2 === 0 ? '' : '_jump'}.gif`"
          @click="seebonTap"
          alt="">
      </div>
      <div :class="s.question_wrap">
        <img :class="s.sp" src="/img/pages/diagnosis/question_bg.svg">
        <img :class="s.pc" src="/img/pages/diagnosis/question_bg_pc.svg">
        <div
          :class="s.question"
          v-html="questions[questionNumber].qDetail[detrailNumber].label"/>
      </div>
      <div :class="s.select_wrap">
        <ul :class="s.answers">
          <li
            v-for="ans in answers"
            :key="ans"
            :class="[
              ansNum === ans.value ? s.active : ansNum || ansNum === 0 ? s.disabled : s.inactive,
              s.answer,
            ]"
            @click="sendAnswer(ans)">
            <div>
              <div v-html="ans.label" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'Diagnosis',
  props: {},
  mixins: [cf],
  data() {
    return {
      questionNumber: 0,
      detrailNumber: 0,
      beforeseebon: null,
      seebonState: null,
      seebonComment: '/img/effects/seebon_comment1.svg',
      commentAnimation: false,
      seebonIrritation: 0,
      wateringCanState: null,
      standByState: false,
      fill1: false,
      fill2: false,
      fill3: false,
      fill4: false,
      scores: [],
      ansNum: null,
      questions: [
        { // score高い方が外交、低い方が内向
          qDetail: [
            {
              label: '休日は一人で趣味に没頭するより、<br class="sp">グループでワイワイ盛り上がる方が<br class="sp">好き！',
              score: null,
            },
            {
              label: 'じぶんの考えやアイデアを<br class="sp">秘めておくより、<br class="sp">ほかの人にも積極的に共有したい。',
              score: null,
            },
            {
              label: '大勢の前で話すのが苦手<br class="sp">という人も多いけど、<br class="sp">わたしはぜんぜんイヤじゃない。',
              score: null,
            },
            {
              label: '気になる人がいたら、<br class="sp">じぶんから声をかけるタイプ。<br class="sp">初対面とか関係ないし！',
              score: null,
            },
          ],
          totalScore: 0,
          mbti: ['E', 'I'],
        },
        { // score高い方が今、低い方が将来
          qDetail: [
            {
              label: 'もし宝くじが当たったら<br class="sp">惜しまずパーっと使う。<br class="sp">貯金するなんてもったいない。',
              score: null,
            },
            {
              label: '大事な試験前日は<br class="sp">それなりに緊張する。でも、<br class="sp">余裕で快眠できちゃうんだよね。',
              score: null,
            },
            {
              label: 'たとえ好きな作品であっても、<br class="sp">わざわざ制作秘話まで<br class="sp">調べたりはしないかな。',
              score: null,
            },
            {
              label: 'ホラー映画を観たとしても、<br class="sp">後には引きずらない。<br class="sp">結局フィクションだしね。',
              score: null,
            },
            {
              label: 'トレンド大好き！ <br class="sp">憧れの有名人やアイドルと<br class="sp">同じものって欲しくならない？',
              score: null,
            },
          ],
          totalScore: 0,
          mbti: ['S', 'N'],
        },
        { // score高い方が思考、低い方が感情
          qDetail: [
            {
              label: '「感動大作！」と話題の<br class="sp">映画や小説に触れても、<br class="sp">人より心に響きにくいかも。',
              score: null,
            },
            {
              label: 'まわりとは意見が違っていた<br class="sp">としても、「正しくない」と<br class="sp">感じたら指摘する。',
              score: null,
            },
            {
              label: '友人からもらったプレゼントを、<br class="sp">フリマサイトに出したり<br class="sp">買い替えるのはアリ。',
              score: null,
            },
          ],
          totalScore: 0,
          mbti: ['T', 'F'],
        },
        { // score高い方が規範、低い方が柔軟
          qDetail: [
            {
              label: 'たとえ傷つくことがあっても<br class="sp">クヨクヨ悩まない。<br class="sp">切り替えて趣味で発散する！',
              score: null,
            },
            {
              label: '気に入ったものがあっても、<br class="sp">同じようなものをすでに<br class="sp">持っていたら買わない。',
              score: null,
            },
            {
              label: 'お金持ちになるために<br class="sp">投資はマストでしょ！ <br class="sp">積極的に勉強したいと思う。',
              score: null,
            },
          ],
          totalScore: 0,
          mbti: ['J', 'P'],
        },
      ],
      answers: [
        {
          label: 'それな！',
          value: 4,
        },
        {
          label: 'ちょっと<br>わかる',
          value: 3,
        },
        {
          label: 'うーん、<br>どうだろう',
          value: 2,
        },
        {
          label: 'そんなこと<br>ないかも',
          value: 1,
        },
        {
          label: 'ぜんぜん<br>ちがう',
          value: 0,
        },
      ],
      jsonTemplate: {
        answer: [],
      },
      nameTypes: {
        enf: 'rucola',
        isf: 'ingen',
        est: 'papurika',
        inf: 'ichijiku',
        esf: 'radish',
        int: 'kiwi',
        ist: 'lycoris',
      },
    };
  },
  created() {},
  watch: {
    scores: {
      handler() {
        this.seebonComment = `/img/effects/seebon_comment${this.scores.length + 1}.svg`;
        this.commentAnimation = true;
      },
      deep: true,
    },
  },
  methods: {
    // 回答
    async sendAnswer(ans) {
      if (this.standByState) return true; // アニメーション待機中はボタン連打しても進まないようにしておく
      this.ansNum = ans.value;
      /**
       * 質問全てに対して同じ位置で回答してもスコアが一定になるわけではないので、逆であるのかをinverseから判定している。
       */
      const score = ans.value;
      this.questions[this.questionNumber].totalScore += score;
      await this.watering();
      this.jsonTemplate.answer.push(score);

      // 全ての質問が終了していたら結果ページへ遷移
      if (this.questionNumber === 3 && this.detrailNumber === 2) {
        const mbtiArr = [];
        this.questions.forEach((row) => {
          const numQuestions = row.qDetail.length;
          const type = (row.totalScore >= (numQuestions * 4) / 2) ? 0 : 1;
          mbtiArr.push(row.mbti[type]);
        });
        await this.uploadJsonData(this.jsonTemplate);
        return true;
      }

      // 該当質問のジャンルの末尾まで行ったら次のジャンルへ渡す
      if (this.detrailNumber === this.questions[this.questionNumber].qDetail.length - 1) {
        this.detrailNumber = 0; // 詳細質問番号をリセット
        this.questionNumber += 1;
        // 水やりアニメーション開始
        this.scores.push(ans.value);
        this.ansNum = null;
        return true;
      }

      this.ansNum = null;
      this.detrailNumber += 1;
      this.scores.push(ans.value);
    },

    // 水やりアニメーション
    async watering() {
      this.standByState = true;
      // シーボンの状態記憶
      // const nowSeebon = this.seebonState;

      // 水やり
      const now = this.formatTimestamp(new Date(), 'HH-mm-ss');
      this.wateringCanState = `/img/characters/gifs/watering_quick_1.gif?datetime=${now}`;
      setTimeout(() => {
        this.wateringCanState = null;
      }, 1300);
      await this.delay(600);

      // シーボンリアクション
      this.seebonState = `/img/characters/gifs/seebon_reaction_${this.scores.length}.gif`;
      setTimeout(() => {
        this.seebonState = null;
      }, 1700);
      await this.delay(800);

      this.standByState = false;
    },

    // シーボンを直接クリックした際の挙動
    async seebonTap() {
      const nowSeebon = this.seebonState;
      if (this.seebonIrritation === 3) {
        this.seebonState = '/img/characters/gifs/seebon_irritation.gif';
        await this.delay(2000);
        this.seebonState = nowSeebon;
        this.seebonIrritation = 0;
      }
      this.seebonIrritation += 1;
    },

    // アニメーションの待ち時間
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    // アニメーション後にフラグを戻す
    resetAnimation() {
      this.commentAnimation = false;
    },

    // バックエンドに送信
    async uploadJsonData(jsonData) {
      const data = {
        dirPath: process.env.VUE_APP_DIAGNOSIS_DIR_PATH, // adonis以下のパス（adonisより上位のパスはbackendで調整）
        ...jsonData,
      };
      await this.axios({
        method: 'post',
        url: '/v1/diagnosis/createJson',
        data,
      })
        .then(async (response) => {
          const res = response.data;
          // 進化アニメーション
          this.fill1 = true;
          await this.delay(250);
          this.fill2 = true;
          await this.delay(250);
          this.fill3 = true;
          await this.delay(250);
          this.fill4 = true;
          // アニメーションONの設定をクエリに含めてページ遷移
          const name = this.nameTypes[res.result.page];
          this.$router.push({
            path: `/result/${name}`,
            query: { effect: 'enable', showResult: 1 },
          });
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.pc {
  display: none;
}
.wrap {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  padding-top: 32px;
  .bg {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    height: 200%;
    width: 200%;
    transform: translate(-50%, -50%) rotate(5deg);
    background-color: var(--primary-pink);
    background-image: url('/img/pages/scoring/scoring_kv_bg.png');
    background-size: 300px auto;
    background-position: center;
    background-repeat: repeat;
  }
}

.choikuse {
  position: absolute;
  top: 5px;
  left: 50%;
  width: 72vw;
  // max-width: 400px;
  z-index: 10;
  transform: translate(-50%, -50%);
}

$content-pdg: 7.5vw;
.content {
  position: relative;
  padding: 0 $content-pdg;
  margin-top: 30px;
  z-index: 2;
}

.seebon {
  &_wrap {
    $w: calc(100vw - 2 * $content-pdg - 6vw * 2);
    margin: 0 auto;
    width: $w;
    height: calc($w * 179 / 229);
    background-image: url('/img/pages/diagnosis/mokumoku.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    position: relative;

    .wateringCan{
      &_active {
        position: absolute;
        top: 15%;
        left: 68%;
        transform: translateX(-50%);
        z-index: 10;
        img {
          width: 85%;
        }
      }
    }

    .q_number {
      position: absolute;
      left: 10%;
      bottom: 50px;
      z-index: 10;
      img {
        width: 8vw;
      }
    }
  }

  &_comment {
    position: absolute;
    z-index: 10;
    top: 22%;
    left: 15%;
    transform: rotate(-5deg);
    font-size: 10px;
    text-align: left;
    img {
      width: 15vw;
    }
  }

  width: calc(100% / 2);
  object-fit: contain;
  position: absolute;
  bottom: 17px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}

@keyframes bound {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  }
  40% {
    transform: translateY(-150px);
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 1);
  }
  60% {
    transform: translateY(-75px);
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 1);
  }
}

@keyframes squash {
  0%, 20%, 50%, 80%, 100% {
    transform: scaleX(1) scaleY(1);
  }
  40% {
    transform: scaleX(1.2) scaleY(0.8);
  }
  60% {
    transform: scaleX(0.8) scaleY(1.2);
  }
}

.bound {
  animation: bounce 1s infinite;
}

.squash {
  animation: squash 1s infinite;
}

.bounce {
  animation: bounce 1.5s infinite, squash 1.5s infinite;
}
// 円を描画
.circle {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: var(--primary-pink); /* 円の色 */
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: expand 1.5s;
}

// 中心から広がる
@keyframes expand {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
    z-index: 100;
  }
  100% {
    width: 200vmax; /* 画面全体を覆う */
    height: 200vmax; /* 画面全体を覆う */
    opacity: 1;
    z-index: 100;
  }
}

.urchin {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  z-index: -1;
  background-image: url('/img/effects/toge_a.svg');
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  animation: expansion .5s;
}

.urchin2 {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  z-index: -1;
  background-image: url('/img/effects/toge_b.svg');
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  animation: expansion .5s;
}

.urchin3 {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  z-index: -1;
  background-image: url('/img/effects/toge_c.svg');
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  animation: expansion .5s;
}

.urchin4 {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  z-index: -1;
  background-image: url('/img/effects/toge_a.svg');
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  animation: expansion .5s;
}

// 中心から外へ展開
@keyframes expansion {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
    z-index: 100;
  }
  100% {
    top: calc(100vmax + 25%);
    width: 300vmax; // 横幅、縦幅ともに画面全体を覆う
    height: 300vmax;
    opacity: 1;
    z-index: 100;
  }
}

.question_wrap {
  $content-w: calc(100vw - $content-pdg * 2);
  margin-top: -38px;
  $question-h: calc($content-w * 118 / 270);
  position: relative;
  height: $question-h;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    &.pc {
      display: none;
    }
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    object-fit: fill;
  }

  .question {
    position: relative;
    z-index: 3;
    padding: 20px 40px;
    font-size: calc(var(--font-default-xs) + 2px);
    font-weight: 700;
    text-align: center;
  }

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    $pdg: 10px;
    height: calc(100% + $pdg);
    width: calc(100% + $pdg);
    background-color: var(--primary-white);
    border-radius: calc($question-h * .25);
  }
}

.answers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 25px;
  > li {
    width: calc((100% - 12px * 2 - 6px) / 3);
    position: relative;
    z-index: 2;
    transition: transform .3s;
    &:not(:nth-child(3n)) {
      margin-right: 12px;
    }
    &:nth-child(n + 4) {
      margin-top: 15px;
    }

    &::before {
      z-index: 1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 6px;
      left: 6px;
      background-color: var(--primary-green);
      border-radius: 50px;
      border: 1px solid var(--primary-black);
      background-image: url('/img/effects/dot_backimage.png');
      background-repeat: repeat;
      background-size: 70px;
      transition: all .3s;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 3;
      width: 20px;
      height: 20px;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 50%);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    > * {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      background-color: var(--primary-white);
      border-radius: 50px;
      border: 1px solid var(--primary-black);
      font-size: var(--font-default-xs);
      font-weight: 700;
      line-height: 1.1;
      width: 100%;
      height: 50px;
      transition: all .2s;
      > * {
        text-align: center;
        margin-top: 4px;
      }
    }

    &.active {
      transform: translate(6px, 6px);
      > * {
        background-color: var(--primary-green);
      }
      &::before {
        top: 0;
        left: 0;
      }
    }
    &.disabled {
      > * {
        transition: color .3s;
        color: var(--primary-gray);
      }
    }
  }
}

$answer-icons:
  '/img/pages/diagnosis/answer_a.svg',
  '/img/pages/diagnosis/answer_b.svg',
  '/img/pages/diagnosis/answer_c.svg',
  '/img/pages/diagnosis/answer_d.svg',
  '/img/pages/diagnosis/answer_e.svg';
@for $i from 1 through 5 {
  .answers {
    > li {
      &:nth-child(#{$i}) {
        &::after {
          background-image: url(nth($answer-icons, $i));
        }
      }
    }
  }
}

@media screen and (min-width: 450px) {
  .question_wrap {
    .question {
      font-size: calc(var(--font-default-sm) + 2px);
    }
  }
  .answers {
    $select-h: 65px;
    $alphabet-wh: 30px;
    margin-top: 35px;
    > li {
      > * {
        height: $select-h;
        font-size: var(--font-default-sm);
      }
      &::before {
        height: $select-h;
      }
      &::after {
        width: $alphabet-wh;
        height: $alphabet-wh;
      }
    }
  }
}

// md
@media screen and (min-width: 768px){
  .sp {
    display: none;
  }
  .pc {
    display: block;
  }

  .wrap {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    padding-top: 32px;
    .bg {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      height: 200%;
      width: 200%;
      transform: translate(-50%, -50%) rotate(5deg);
      background-color: var(--primary-pink);
      background-image: url('/img/pages/scoring/scoring_kv_bg.png');
      background-size: 300px auto;
      background-position: center;
      background-repeat: repeat;
    }
  }

  .choikuse {
    position: absolute;
    top: 0;
    left: 50%;
    width: 72vw;
    max-width: 700px;
    z-index: 10;
    transform: translate(-50%, -50%);
    @media screen and (min-width:1000px) {
      top: 5%;
    }
  }

  $content-pdg: 7.5vw;
  .content {
    position: relative;
    padding: 0 $content-pdg;
    margin: 100px auto;
    z-index: 2;
  }

  .seebon {
    &_wrap {
      $w: calc(100vw - 2 * $content-pdg - 6vw * 2);
      margin: 0 auto;
      width: $w;
      max-width: 650px;
      height: calc($w * 150 / 240);
      max-height: 540px;
      background-image: url('/img/pages/diagnosis/mokumoku.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      position: relative;

      .q_number {
        position: absolute;
        left: 10%;
        bottom: 24%;
        z-index: 10;
        img {
          width: 7vw;
          max-width: 60px;
        }
      }
    }

    &_comment {
      position: absolute;
      z-index: 10;
      top: 29%;
      left: 20%;
      transform: rotate(-5deg);
      img {
        width: 10vw;
        max-width: 100px;
      }
    }

    width: calc(100% / 2);
    max-width: 400px;
    object-fit: contain;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, 0);
  }


  .question_wrap {
    $content-w: calc(100vw - $content-pdg * 2);
    max-width: 800px;
    margin: 0 auto;
    margin-top: -80px;
    $question-h: calc($content-w * 41 / 270);
    position: relative;
    height: $question-h;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      &.sp {
        display: none;
      }
      &.pc {
        display: block;
      }
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      object-fit: fill;
    }

    .question {
      position: relative;
      z-index: 3;
      padding: 20px;
      font-size: var(--font-default-md);
      font-weight: 700;
      text-align: center;
    }

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      $pdg: 10px;
      height: calc(100% + $pdg);
      max-height: 132.88px;
      width: calc(100% + $pdg);
      background-color: var(--primary-white);
      border-radius: min(calc($question-h * .31), 32px);
    }
  }

  .answers {
    $select-h: 75px;
    $alphabet-wh: 35px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    column-gap: 10px;
    margin-top: 45px;
    > li {
      width: calc((100% - 12px * 2 - 6px) / 3);
      position: relative;
      z-index: 2;
      transition: transform .3s;
      &:not(:nth-child(3n)) {
        margin-right: 0;
      }
      &:nth-child(n + 4) {
        margin-top: 0;
      }

      &::before {
        z-index: 1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 6px;
        left: 6px;
        background-color: var(--primary-green);
        border-radius: 50px;
        border: 1px solid var(--primary-black);
        background-image: url('/img/effects/dot_backimage.png');
        background-repeat: repeat;
        background-size: 70px;
        transition: all .3s;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 3;
        width: $alphabet-wh;
        height: $alphabet-wh;
        bottom: 108%;
        left: 50%;
        transform: translate(-50%, 50%);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      > * {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        background-color: var(--primary-white);
        border-radius: 50px;
        border: 1px solid var(--primary-black);
        font-size: var(--font-default-md);
        font-weight: 700;
        line-height: 1.1;
        width: 100%;
        height: $select-h;
        > * {
          text-align: center;
          margin-top: 4px;
        }
      }

      &.inactive {
        &:hover {
          transform: translate(6px, 6px);
          &::before {
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  $answer-icons:
    '/img/pages/diagnosis/answer_a.svg',
    '/img/pages/diagnosis/answer_b.svg',
    '/img/pages/diagnosis/answer_c.svg',
    '/img/pages/diagnosis/answer_d.svg',
    '/img/pages/diagnosis/answer_e.svg';
  @for $i from 1 through 5 {
    .answers {
      > li {
        &:nth-child(#{$i}) {
          &::after {
            background-image: url(nth($answer-icons, $i));
          }
        }
      }
    }
  }
}

// pc
@media screen and (min-width: 1220px) {
  .question_wrap {
    .question {
      font-size: var(--font-default);
    }
    &::before {
      border-radius: 30px;
    }
  }
  .answers {
    $select-h: 85px;
    $alphabet-wh: 40px;
    > li {
      > * {
        height: $select-h;
        font-size: var(--font-default);
      }
      &::after {
        width: $alphabet-wh;
        height: $alphabet-wh;
      }
    }
  }
}
</style>

<style lang="scss">
@media screen and (min-width: 760px) {
  .br_sp {
    display: none;
  }
}
</style>
